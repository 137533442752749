import {Grid, Row, Button, Col, Divider} from 'rsuite';
import useCurrentBreakpoint from 'lib/breakpoint';
import LandingCard from 'views/Landing/components/LandingCard';
import model1 from 'assets/PMicons-19.png';
import model2 from 'assets/PMicons-20.png';
import './landingModel.scss';

function LandingModel (){
    return (
        <Grid className="landing-grid pt-0">
            <Divider className="m-0 mb-3"/>
            <Row gutter={24}>
                <Col className='mb-1'>
                    <h1 className="landing-title font-bossaBold">
                        Kolaborasi Yang Fleksibel
                    </h1>
                </Col>
                <Col xs={24} md={16} className="mb-5">
                    <p>
                        Temukan pilihan kolaborasi sesuai ketersediaan waktu praktisi.
                    </p>
                </Col>
                <Col xs={24}>
                    <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                        <LandingCard>
                            <Col md={8} className='model-kolaborasi-img'>
                                <img src={ model1 } alt="mbkm-pm-model-2" width={"100%"}></img>
                            </Col>
                            <Col md={16}>
                                <div className='card-title'>
                                    Kolaborasi Pendek
                                </div>
                                <div className='card-text'>
                                    Persiapan dan penyampaian perkuliahan, serta evaluasi pengajaran. Komitmen waktu minimal 4 jam per semester. Hanya berlaku untuk praktisi yang belum memiliki NIDK atau NUP. Satu praktisi maksimal mengajar di 2 mata kuliah kolaborasi pendek. 
                                </div>
                            </Col>
                        </LandingCard>
                        <LandingCard>
                            <Col md={8} className='model-kolaborasi-img'>
                                <img src={ model2 } alt="mbkm-pm-model-2" width={"100%"}></img>
                            </Col>
                            <Col md={16}>
                                <div className='card-title'>
                                    Kolaborasi Intensif
                                </div>
                                <div className='card-text'>
                                    Perencanaan perkuliahan, persiapan dan penyampaian perkuliahan, serta evaluasi pengajaran. Komitmen waktu minimal 15 jam per semester. 
                                </div>
                            </Col>
                        </LandingCard>
                    </div>
                </Col>
            </Row>
        </Grid>
    );
}

export default LandingModel;