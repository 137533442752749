import React from "react";
import "./landingCard.scss";

function LandingCard(props) {
  return (
    <div className={"landing-card "+props.className??''} style={{ ...props.style }}>
      {props.children}
    </div>
  );
}

export default LandingCard;
