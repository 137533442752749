import {Container, Grid, Row, Col, Button} from "rsuite";

function SyaratDanKetentuan(){
    return (
        <Container style={{padding: 0, background: '#FFF'}}>
            <div style={{backgroundColor: '#F5F5F5', color: "#25282B"}}>
                <Grid className="landing-grid">
                    <Row gutter={24}>
                        <Col className='mb-1' xs={24}>
                            <h2 className="font-bossaBold">
                                Oops!
                            </h2>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Grid className="landing-grid py-6">
                <Row gutter={24}>
                    <Col xs={24} className="pb-2">
                      <p>Halaman sedang dalam pengembangan, silakan kembali lagi.</p>
                    </Col>
                </Row>
            </Grid>
        </Container>
    )
}

export default SyaratDanKetentuan;