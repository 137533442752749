import {Container, Grid, Row, Col, Button} from "rsuite";

function InfoSession() {
    // window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLScKGKHgINMHl7Xw6ofvicDgmqPlpR6QsJUuW6X-4W_6JcuRRA/viewform'
    // return (
    //     <></>
    // )
    return (
        <Grid className="landing-grid">
            <Row style={{marginBottom: 2+'em'}}>
                <Col md={24}>
                    <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                        Jadwal <i>Info Session</i> Bimbingan Teknis Registrasi Praktisi
                    </h1>
                    <p>Silakan mendaftar untuk menghadiri <i>info session</i> terkait penjelasan teknis mengenai Registrasi Praktisi Program Praktisi Mengajar. Link zoom akan dikirimkan ke email Anda setelah Anda mendaftar. Mohon dapat memeriksa folder <i>spam</i> atau <i>updates</i> apabila Anda belum menerima link zoom pada <i>inbox</i> Anda.</p>
                </Col>
            </Row>
            <Col xs={24}>
                <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Kamis, 16 Juni 2022</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Pukul 12:00 WIB
                        </div>
                        <Button appearance="primary" target="_blank" href="https://ugm-id.zoom.us/meeting/register/tJwrcOquqD4rE9MBT04hB9y3Z115mM-xQ5-K" className='text-uppercase bg-primary landing-button text-white mb-1'>Daftarkan Saya</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Kamis, 16 Juni 2022</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Pukul 16:00 WIB
                        </div>
                        <Button appearance="primary" target="_blank" href="https://ugm-id.zoom.us/meeting/register/tJEpce-spz4tE9OgVgmvOKhmevbZsVR8LKE0" className='text-uppercase bg-primary landing-button text-white mb-1'>Daftarkan Saya</Button>
                    </div>
                </div>
            </Col>
            <Row style={{marginBottom: 2+'em', marginTop: 2+'em'}}>
                <Col md={24}>
                    <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                        Jadwal <i>Info Session</i> Praktisi
                    </h1>
                    <p>Silakan mendaftar untuk menghadiri <i>info session</i> terkait penjelasan detail mengenai Program Praktisi Mengajar. Link zoom akan dikirimkan ke email Anda setelah Anda mendaftar. Mohon dapat memeriksa folder <i>spam</i> atau <i>updates</i> apabila Anda belum menerima link zoom pada <i>inbox</i> Anda.</p>
                </Col>
            </Row>
            <Col xs={24}>
                <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Jumat, 3 Juni 2022</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Pukul 16:30 - 17:30 WIB
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sabtu, 4 Juni 2022</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Pukul 09:00 - 10:00 WIB
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sabtu, 11 Juni 2022</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Pukul 09:00 - 10:00 WIB
                        </div>
                    </div>
                </div>
            </Col>
        </Grid>
    );
}

export default InfoSession;
