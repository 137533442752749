function SKReviewer1(){
    window.location.href = 'https://drive.google.com/file/d/1oYj_07Wm6MquPzL4mJIb3mENpNj3fkMe/view?usp=sharing'
    return (
        <></>
    )
}

export default SKReviewer1;




