function URLRoutingF(){
    window.location.href = 'https://ugm-id.zoom.us/j/95923501014?pwd=b1p4THBYdTdHUFNhVHdQNzNmS1NKUT09'
    return (
        <></>
    )
}

export default URLRoutingF;


