import {Row, Col, Button} from 'rsuite';
import LandingCard from 'views/Landing/components/LandingCard';
import starIcon from 'assets/Star 1.png';


function KolaborasiPraktisi(){
    return (
        <Row>
            <Col xs={24}>
                <LandingCard className="mb-0 p-0 d-flex align-items-stretch flex-column-xs">
                    <div className='card-image'>
                        <img src={require('assets/praktisi.jpeg')} alt=""/>
                    </div>
                    <div className='card-content p-4 d-flex flex-column align-items-start justify-content-between'>
                        <div className='card-text mb-3'>
                            Mari bergotong royong membangun bangsa untuk mempersiapkan SDM unggul dengan berkontribusi langsung dalam meningkatkan kualitas pembelajaran di kampus.
                        </div>
                        <div>
                            {/* <Button appearance="primary" target="_blank" href={`/deckpraktisi`} className='btn-blue text-uppercase landing-button mr-1 mb-1'>Lihat Materi Presentasi Untuk Praktisi</Button>
                            <Button appearance="primary" target="_blank" href={`/bukuteknispraktisi`} className='btn-blue text-uppercase landing-button mr-1 mb-1'>Buku Teknis Praktisi</Button> */}
                            <Button appearance="primary" target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}/sys/registrasi/signup_proses`} className='bg-primary text-uppercase landing-button text-white mb-1'>Daftar sebagai Praktisi</Button>
                        </div>
                    </div>
                </LandingCard>
                {/* <h4 className='landing-title font-bossaBold mt-5 mb-3'>
                    Mengapa Anda Perlu Berkolaborasi Sebagai Praktisi?
                </h4>
                <div className='d-grid grid-lg-3 grid-md-3 grid-xs-1'>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/praktisi-1.png') } alt="praktisi-1" height={"150px"}></img>
                        <div className='card-text'>
                            Membangun citra personal dan berbagi ilmu kepada masyarakat
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/praktisi-2.png') } alt="praktisi-2" height={"150px"}></img>
                        <div className='card-text'>
                            Mengembangkan jaringan lintas industri
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/praktisi-3.png') } alt="praktisi-3" height={"150px"}></img>
                        <div className='card-text'>
                            Mengembangkan diri dan mendapat pengalaman baru
                        </div>
                    </LandingCard>
                </div>
                <h4 className='landing-title font-bossaBold mt-2 mb-3'>
                    Lima Syarat Mudah bagi Praktisi untuk Berkolaborasi
                </h4> */}
                {/* <div className='d-grid grid-lg-3 grid-md-2 grid-xs-1'>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            1
                        </div>
                        <div className='card-text'>
                            Memiliki motivasi kuat untuk berkontribusi di bidang pendidikan
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            2
                        </div>
                        <div className='card-text'>
                            Sudah bekerja dan/atau membuka usaha minimal 3 tahun pengalaman kumulatif sejak lulus D3, D4, dan/atau S1
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            3
                        </div>
                        <div className='card-text'>
                            Saat ini masih bekerja/usaha masih beroperasi
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            4
                        </div>
                        <div className='card-text'>
                            Memiliki keahlian yang dapat diajarkan
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            5
                        </div>
                        <div className='card-text'>
                            Tidak berstatus sebagai dosen dengan NIDN
                        </div>
                    </LandingCard>
                </div> */}
                {/* <Row gutter={24}>
                    <Col xs={24} md={16} >
                        <ul className='py-2 mb-0 list-syarat' style={{ listStyle: 'none'}}>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon" /></div> Memiliki motivasi kuat untuk berkontribusi di bidang pendidikan</div></li>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon" /></div> Telah bekerja dan/atau membuka usaha sendiri (berwirausaha) selama minimal tiga tahun, dihitung secara kumulatif sejak lulus perguruan tinggi minimal D3 atau sederajat. Ketentuan ini dikecualikan bagi atlet atau seniman yang memiliki kualifikasi sesuai dengan bidangnya masing-masing </div></li>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon" /></div> Saat ini masih bekerja/usaha masih beroperasi</div></li>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon" /></div> Memiliki keahlian yang dapat diajarkan</div></li>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon" /></div> Tidak memiliki Nomor Induk Dosen Nasional (NIDN) atau Nomor Induk Tenaga Kependidikan (NITK)</div></li>
                        </ul>
                    </Col>
                </Row>
                <h4 className='landing-title font-bossaBold mt-5 mb-3'>
                    Praktisi bisa Segera Berkontribusi dengan Tiga Langkah Sederhana.
                </h4>
                <div className='d-grid grid-lg-1 grid-md-1 grid-xs-1'>
                    <LandingCard className="mb-0">
                        <div className="d-flex align-items-center">
                            <div className="kolaborasi-praktisi-number pr-2">01.</div>
                            <div>
                                <div className='card-title'>
                                    <h5>Isi Formulir</h5>
                                </div>
                                <div className='card-text'>
                                    Lengkapi formulir dan persyaratan dokumen secara daring dengan klik tombol <a style={{ textDecoration: 'underline' }} href="/#home">Daftar Sebagai Praktisi</a> di atas.
                                </div>

                            </div>
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className="d-flex align-items-center">
                            <div className="kolaborasi-praktisi-number pr-2">02.</div>
                            <div>
                                <div className='card-title'>
                                    <h5>Publikasi Profil</h5>
                                </div>
                                <div className='card-text'>
                                    Profil Anda akan dipublikasikan sehingga dapat dilihat dan diakses oleh mitra akademisi.
                                </div>
                            </div>
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className="d-flex align-items-center">
                            <div className="kolaborasi-praktisi-number pr-2">03.</div>
                            <div>
                                <div className='card-title'>
                                    <h5>Komunikasi dengan Akademisi</h5>
                                </div>
                                <div className='card-text'>
                                    Anda akan dihubungi oleh mitra akademisi melalui surel jika profil Anda cocok dengan mata kuliah yang akan dibuka.
                                </div>
                            </div>
                        </div>
                    </LandingCard>
                </div> */}
            </Col>
        </Row>
    );
}

export default KolaborasiPraktisi;
