import {Container, Grid, Row, Col, Button} from "rsuite";
import "./Info.scss";
import info_pendaftaran_website from '../../assets/banner/info_pendaftaran_website.png';
import info_pertanyaan_FAQ from '../../assets/banner/info_pertanyaan_FAQ.png';
import info_sesiprak from '../../assets/banner/info_sesiprak.png';
import info_sess_PT from '../../assets/banner/info_sess_PT.png';
import info_sesipraktisi_pilihan from '../../assets/banner/info_sesipraktisi_pilihan.png';
import info_instagram from '../../assets/banner/info_instagram.png';
import info_twitter from '../../assets/banner/info_twitter.png';
import info_facebook from '../../assets/banner/info_facebook.png';
import info_youtube from '../../assets/banner/info_youtube.png';
import info_tiketbantuan from '../../assets/banner/info_tiketbantuan.png';

function Info() {
    return (
        <Grid className="landing-grid info-linktree">
            <Row style={{marginBottom: 2+'em'}}>
                <Col md={24}>
                    <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                        Praktisi Mengajar
                    </h1>
                    <p>Segera daftar agar praktisi dan perguruan tinggi bisa segera berdiskusi untuk persiapan kelas semester depan!</p>
                    <p>Penutupan pendaftaran praktisi: 17 Juni 2022. <br/>
                    Penutupan pendaftaran perguruan tinggi: 24 Juni 2022.</p>
                </Col>
            </Row>
            <Col xs={24}>
                <div className='d-grid grid-lg-3 grid-md-2 grid-xs-1'>
                    <a target="_blank" href="/" className="text-center">
                        <div className='card-title'>
                            <img src={ info_pendaftaran_website } alt="Daftar Praktisi Mengajar" width="100%"></img>
                            <h6>Pendaftaran: praktisimengajar.id</h6>
                        </div>
                    </a>
                    <a target="_blank" href="/#faq" className="text-center">
                        <div className='card-title'>
                            <img src={ info_pertanyaan_FAQ } alt="FAQ Praktisi Mengajar" width="100%"></img>
                            <h6>Pertanyaan yang Sering Diajukan</h6>
                        </div>
                    </a>
                    <a target="_blank" href="/info-session" className="text-center">
                        <div className='card-title'>
                            <img src={ info_sesiprak } alt="Info Session Praktisi" width="100%"></img>
                            <h6>Info Session Praktisi</h6>
                        </div>
                    </a>
                    <a target="_blank" href="/info-session-pt" className="text-center">
                        <div className='card-title'>
                            <img src={ info_sess_PT } alt="Info Session Perguruan Tinggi" width="100%"></img>
                            <h6>Info Session Perguruan Tinggi</h6>
                        </div>
                    </a>
                    <a target="_blank" href="/SeriPraktisiPilihan" className="text-center">
                        <div className='card-title'>
                            <img src={ info_sesipraktisi_pilihan } alt="Sesi Praktisi Pilihan" width="100%"></img>
                            <h6>Sesi Praktisi Pilihan</h6>
                        </div>
                    </a>
                    <a target="_blank" href="https://instagram.com/praktisimengajar" className="text-center">
                        <div className='card-title'>
                            <img src={ info_instagram } alt="Instagram Praktisi Mengajar" width="100%"></img>
                            <h6>Instagram</h6>
                        </div>
                    </a>
                    <a target="_blank" href="https://twitter.com/PMengajar" className="text-center">
                        <div className='card-title'>
                            <img src={ info_twitter } alt="Twitter Praktisi Mengajar" width="100%"></img>
                            <h6>Twitter</h6>
                        </div>
                    </a>
                    <a target="_blank" href="https://www.facebook.com/Praktisi-Mengajar-Kampus-Merdeka-100234405952680" className="text-center">
                        <div className='card-title'>
                            <img src={ info_facebook } alt="Facebook Praktisi Mengajar" width="100%"></img>
                            <h6>Facebook</h6>
                        </div>
                    </a>
                    <a target="_blank" href="https://youtu.be/AKDhCKuxoFE" className="text-center">
                        <div className='card-title'>
                            <img src={ info_youtube } alt="Youtube Praktisi Mengajar" width="100%"></img>
                            <h6>Youtube</h6>
                        </div>
                    </a>
                    <a target="_blank" href={`${process.env.REACT_APP_CONTACT_US_URL}`} className="text-center">
                        <div className='card-title'>
                            <img src={ info_tiketbantuan } alt="Tiket Bantuan Praktisi Mengajar" width="100%"></img>
                            <h6>Tiket Bantuan</h6>
                        </div>
                    </a>
                </div>
            </Col>
        </Grid>
    );
}

export default Info;
