function HubungiKami(){
    window.location.href = 'https://pusatinformasi.praktisimengajar.id/hc/en-gb/requests/new'
    return (
        <></>
    )
}

export default HubungiKami;



