import useBreakpoint from 'use-breakpoint';

/**
 * It is important to bind the object of breakpoints to a variable for memoization to work correctly.
 * If they are created dynamically, try using the `useMemo` hook.
 */
const BREAKPOINTS = { xs: 0, sm: 480, md: 992, lg: 1200, xl: 1600 }
const BREAKPOINTS2 = { xs: {min: 0, max: 479}, sm: {min: 480, max: 991}, md: {min: 992, max: 1199}, lg: {min: 1200, max: 1599}, xl: {min: 1600} }
// const BREAKPOINTS = { mobile: 0, tablet: 768, desktop: 1280 }

// const useCurrentBreakpoint = () => {
//   const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'lg', false);
//   return breakpoint;
// };

const useHigherBreakpoint = (bp) => {
  const minW1 = BREAKPOINTS2[bp].min;
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'lg', false);
  return minWidth >= minW1;
};

const useLowerBreakpoint = (bp) => {
  const maxW1 = BREAKPOINTS2[bp].min;
  const { breakpoint, maxWidth, minWidth } = useBreakpoint(BREAKPOINTS, 'lg', false);
  if(breakpoint === 'xl') return false;
  return maxWidth <= maxW1;
};

export {useHigherBreakpoint, useLowerBreakpoint};
