function DeckOnboardingPraktisi(){
    window.location.href = 'https://drive.google.com/file/d/1Q1ZWJBWKHwTWQjzQOlhXsPz1SY3SlVWy/view?usp=sharing'
    return (
        <></>
    )
}

export default DeckOnboardingPraktisi;


