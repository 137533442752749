import {useState} from 'react';
import {Divider} from 'rsuite';
import './landingTabs.scss';

function LandingTabs(tabProps) {
    const [active, setActive] = useState(0);

    return (
        <div className="wrapper">
            <div className="tabs">
                {tabProps.children.map(({ props }, index) => {
                    return (
                        <div key={index} className={active === index ? 'justify-content-center tabitem kolaborasi-tab-item' : 'justify-content-center tabitem kolaborasi-tab-item tabitem--inactive'} onClick={() => setActive(index)}>
                            <p className="tabitem__title">{props.title}</p>
                        </div>
                    )
                })}
            </div>
            <Divider className='mt-0' />
            <div className="content">
                {tabProps.children.map(({ props }, index) => {
                    return active === index ? props.children : ''
                })}
            </div>
        </div>
    )
}

export default LandingTabs;