import {Grid, Row, Col, Divider} from 'rsuite';
import LandingTabs from '../../components/LandingTabs';
import LandingTabItem from 'views/Landing/components/LandingTabItem';
import KolaborasiPraktisi from './KolaborasiPraktisi';
import KolaborasiPT from './KolaborasiPT';
import KolaborasiMahasiswa from './KolaborasiMahasiswa';
import KolaborasiIP from './KolaborasiIP';
import KolaborasiDosen from './KolaborasiDosen';
import './landingKolaborasi.scss';

function LandingKolaborasi(){
    const tabItems = [
        {
          title: 'Praktisi',
          content: <KolaborasiPraktisi key={0}/>,
        },
        {
          title: 'Perguruan Tinggi',
          content: <KolaborasiPT key={1}/>,
        },
        // {
        //   title: 'Dosen',
        //   content: <KolaborasiDosen key={4}/>,
        // },
        // {
        //   title: 'Institusi Praktisi',
        //   content: <KolaborasiIP key={3}/>,
        // },
        // {
        //   title: 'Mahasiswa',
        //   content: <KolaborasiMahasiswa key={2}/>,
        // },
      ];
    return (
        <Grid className="landing-grid pt-0" id="kolaborasi">
            <Divider className="m-0 mb-3"/>
            <Row gutter={24}>
                <Col className='mb-1'>
                    <h1 className="landing-title font-bossaBold">
                        Siapapun Bisa Berkolaborasi
                    </h1>
                </Col>
                <Col xs={24}>
                    <LandingTabs>
                      { tabItems.map((item, index) => {
                          return <LandingTabItem title={item.title} key={index}>
                            {item.content}
                          </LandingTabItem>
                        })
                      }
                    </LandingTabs>
                </Col>
            </Row>
        </Grid>
    );
}

export default LandingKolaborasi;