function TemplateSuratPernyataan(){
    window.location.href = 'https://docs.google.com/document/d/1RUBB4JTC2u7Rj6hdTl6MBTTHcHBqlGYD/export?format=doc'
    return (
        <></>
    )
}

export default TemplateSuratPernyataan;




