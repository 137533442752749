import { Grid, Row, Button, Col } from 'rsuite';
import { useLowerBreakpoint } from 'lib/breakpoint';
import LandingAccordion from '../components/LandingAccordion';
import { useNavigate } from 'react-router-dom';

function LandingSyarat (){
    const navigate = useNavigate();
    return (
        <Grid className="landing-grid" id="faq" style={{paddingTop: useLowerBreakpoint("md")?'2.5rem':'5rem'}}>
            <Row gutter={24}>
                <Col className='mb-1' xs={24}>
                    <h1 className="landing-title font-bossaBold">
                        Seputar Program Praktisi Mengajar
                    </h1>
                </Col>
                <Col xs={24} md={24}>
                    <p className='mb-5'>
                        Lihat jawaban atas pertanyaan Anda di sini.
                    </p>
                    <LandingAccordion title={'Apa itu Program Praktisi Mengajar?'}>
                        <p>
                        Praktisi Mengajar adalah Program yang diinisiasi oleh Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia agar lulusan perguruan tinggi lebih siap untuk masuk ke dunia kerja.
                        </p>
                    </LandingAccordion>
                    <LandingAccordion title={'Mengapa perlu ada Program Praktisi Mengajar?'}>
                        <p>
                            Terdapat kesenjangan antara keahlian lulusan dan kebutuhan dunia kerja sehingga dunia kerja seringkali mengeluhkan kualitas lulusan perguruan tinggi. Menindaklanjuti  tantangan tersebut, Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi menginisiasi Program Praktisi Mengajar untuk mendorong kolaborasi aktif antara perguruan tinggi dan dunia kerja. Kolaborasi yang dilakukan dalam pengembangan mata kuliah ini akan meningkatkan relevansi mata kuliah yang diajarkan di perguruan tinggi dengan kebutuhan dunia kerja. Kedepannya, melalui program ini, proses alih pengetahuan dan keahlian dari dunia kerja ke sivitas akademika dapat terus berkesinambungan sehingga perguruan tinggi bisa menghasilkan lulusan yang siap berkarya di dunia kerja.
                        </p>
                    </LandingAccordion>
                    <LandingAccordion title={'Bagaimana cara mendaftarkan mata kuliah?'}>
                        <p>
                            Perguruan tinggi dapat mendaftarkan mata kuliah dengan cara sudah melengkapi profil Perguruan tinggi dan koordinator perguruan tinggi
                        </p>
                    </LandingAccordion>
                    <LandingAccordion title={'Bagaimana cara mendaftar sebagai praktisi dalam Program ini?'}>
                        <p>
                            Silakan lihat <a target="_blank" href="https://drive.google.com/file/d/1oNpzTx-s6udAtvf0o0LMbSYAmX4tuo75/view?usp=share_link">panduan</a> atau daftarkan diri Anda dengan <a target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}/sys/registrasi/signup_proses_praktisi`}>klik disini</a>.
                        </p>
                    </LandingAccordion>
                    <div className="d-flex justify-content-end button-all-faq-wrapper">
                        <Button appearance="primary" target="_blank" href="https://pusatinformasi.praktisimengajar.id/hc/en-gb" className='bg-primary landing-button text-white'>Lihat Semua Pertanyaan</Button>
                    </div>
                </Col>
            </Row>
        </Grid>
    );
}

export default LandingSyarat;
