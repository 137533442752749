function SuratPernyataan(){
    window.location.href = 'https://docs.google.com/document/d/1K9oETbKifgqIncWl0WyYlj9UUBGb-HYy/export?format=doc'
    return (
        <></>
    )
}

export default SuratPernyataan;


