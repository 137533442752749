import {Grid, Row, Col} from "rsuite";

function SeriPraktisi() {
    return (
        <Grid className="landing-grid">
            <Row style={{marginBottom: 2+'em'}}>
                <Col md={24}>
                    <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                        Seri Praktisi Pilihan
                    </h1>
                    <p>Silakan mendaftar untuk menghadiri Seri Praktisi Pilihan di mana peserta dapat langsung mengikuti kelas yang diajarkan langsung oleh praktisi ahli pilihan. Link Zoom akan dikirimkan ke email Anda setelah Anda mendaftar. Mohon dapat memeriksa folder spam atau updates apabila Anda belum menerima link Zoom pada inbox Anda.</p>
                </Col>
            </Row>
            <Col xs={24}>
                <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 1</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 14-Jun-2022, 14:00-15:00 WIB<br/>
                            Penggunaan Scrum untuk Pengembangan Produk Inovatif<br/>
                            Joshua Partogi
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 2</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 21-Jun-2022, 14:00-15:00 WIB<br/>
                            Tema: Personal vs Creative Branding<br/>
                            Pembicara: Muhammad Jupaka
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 3</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 28-Jun-2022, 14:00-15:00 WIB<br/>
                            Membangun Karakter melalui Kepribadian dan <i>Growth Mindset</i><br/>
                            Pembicara: Flavia Norpina Sungkit
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 4</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 5-Jul-2022, 14:00-15:00 WIB<br/>
                            Cara Memulai Digital Art Business 101<br/>
                            Pembicara: Yulia Ratnasari
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 5</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 12-Jul-2022, 14:00-15:00 WIB<br/>
                            Tempe sebagai Warisan Budaya Indonesia<br/>
                            Pembicara : Wida Winarno
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 6</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Selasa, 19-Jul-2022, 14:00-15:00 WIB<br/>
                            Strategi Pemasaran dan Komunikasi untuk Bisnis Kreatif<br/>
                            Pembicara : Prilly Latuconsina
                        </div>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi Praktisi Pilihan 7</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            Perlindungan Kekayaan Intelektual<br/>
                            Pembicara : Justi Kusumah<br/>
                            Selasa, 26-Jul-2022, 14:00-15:00 WIB
                        </div>
                    </div>
                </div>
            </Col>
        </Grid>
    );
}

export default SeriPraktisi;
