function CeritaPraktisiMengajar(){
    window.location.href = 'https://forms.gle/kSXCNqETq7Bq9TAu5'
    return (
        <></>
    )
}

export default CeritaPraktisiMengajar;


