function DataPraktisiG3(){
    window.location.href = 'https://forms.gle/wz31voPNckzP4QiQ7'
    return (
        <></>
    )
}

export default DataPraktisiG3;




