import { Routes, Route, useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

import Landing from "../views/Landing/Landing";
import MainLanding from "views/Layout/MainLanding";
import FAQ from "../views/FAQ/FAQ";
import KebijakanPrivasi from "../views/Policies/KebijakanPrivasi";
import SyaratDanKetentuan from "../views/Policies/SyaratDanKetentuan";
import Info from "../views/Info/Info";
import SeriPraktisi from "../views/SeriPraktisi/SeriPraktisi";
import PendaftaranPT from "../views/PendaftaranPT/PendaftaranPT";
import UnderDevelopment from "../views/Misc/UnderDevelopment";
import BimtekPendaftaranPT from "../views/Misc/BimtekPendaftaranPT";
import BimtekDashboardPT from "../views/Misc/BimtekDashboardPT";
import BimtekReviewer from "../views/Misc/BimtekReviewer";
import BimtekProposalGDM1 from "../views/Misc/BimtekProposalGDM1";
import BimtekProposalGDM2 from "../views/Misc/BimtekProposalGDM2";
import DeckBimtekPT from "../views/Misc/DeckBimtekPT";
import PanduanTeknisPT from "../views/Misc/PanduanTeknisPT";
import PanduanTeknisReviewer from "../views/Misc/PanduanTeknisReviewer";
import ReviewerPT from "../views/Misc/ReviewerPT";
import DeckPraktisi from "../views/Misc/DeckPraktisi";
import DeckPerguruanTinggi from "../views/Misc/DeckPerguruanTinggi";
import DeckBimtekReviewer from "../views/Misc/DeckBimtekReviewer";
import DeckOnboardingPraktisi from "../views/Misc/DeckOnboardingPraktisi";
import OnePager from "../views/Misc/OnePager";
import BukuPanduan from "../views/Misc/BukuPanduan";
import InfoReviewer from "../views/Misc/InfoReviewer";
import InfoSession from "../views/Misc/InfoSession";
import InfoSessionPT from "../views/Misc/InfoSessionPT";
import BukuTeknisPraktisi from "../views/Misc/BukuTeknisPraktisi";
import BukuOnboardingPraktisi from "../views/Misc/BukuOnboardingPraktisi";
import PanduanTeknisOnboarding from "../views/Misc/PanduanTeknisOnboarding";
import PendaftaranPraktisi from "../views/Misc/PendaftaranPraktisi";
import RegistrasiPraktisi from "../views/Misc/RegistrasiPraktisi";
import SuratPernyataan from "../views/Misc/SuratPernyataan";
import TemplateSuratPernyataan from "../views/Misc/TemplateSuratPernyataan";
import RevisiProposal from "../views/Misc/RevisiProposal";
import RevisiProposalG3 from "../views/Misc/RevisiProposalG3";
import URLRoutingA from "../views/Misc/URLRoutingA";
import URLRoutingB from "../views/Misc/URLRoutingB";
import URLRoutingC from "../views/Misc/URLRoutingC";
import URLRoutingD from "../views/Misc/URLRoutingD";
import URLRoutingE from "../views/Misc/URLRoutingE";
import URLRoutingF from "../views/Misc/URLRoutingF";
import URLRoutingG from "../views/Misc/URLRoutingG";
import DataPraktisiG31 from "../views/Misc/DataPraktisiG31";
import BimtekFGDReviewer from "../views/Misc/BimtekFGDReviewer";
import DataPraktisi from "../views/Misc/DataPraktisi";
import PesertaG1 from "../views/Misc/PesertaG1";
import DataPraktisiG2 from "../views/Misc/DataPraktisiG2";
import PesertaG2 from "../views/Misc/PesertaG2";
import SeleksiG2 from "../views/Misc/SeleksiG2";
import DataPraktisiG3 from "../views/Misc/DataPraktisiG3";
import PesertaG3 from "../views/Misc/PesertaG3";
import DataPraktisiG4 from "../views/Misc/DataPraktisiG4";
import SKREviewer1 from "../views/Misc/SKReviewer1";
import PesertaBimtekPraktisiMengajar from "../views/Misc/PesertaBimtekPraktisiMengajar";
import InfoPM from "../views/Misc/InfoPM";
import HapusKelas from "../views/Misc/HapusKelas";
import TelegramPraktisiMengajar from "../views/Misc/TelegramPraktisiMengajar";
import HubungiKami from "../views/Misc/HubungiKami";
import MateriBimtekGDM1 from "../views/Misc/MateriBimtekGDM1";
import MateriBimtekGDM2 from "../views/Misc/MateriBimtekGDM2";
import BimtekKhususGDMSesi2 from "../views/Misc/BimtekKhususGDMSesi2";
import BimtekKhususGDMSesi3 from "../views/Misc/BimtekKhususGDMSesi3";
import HandbookKolaborasi from "../views/Misc/HandbookKolaborasi";
import MateriTeknisPPG from "../views/Misc/MateriTeknisPPG";
import ContohSuratPernyataan from "../views/Misc/ContohSuratPernyataan";
import DataPMG13 from "../views/Misc/DataPMG13";
import PpgPb from "../views/Misc/PpgPb";
import MateriPPGPrajab from "../views/Misc/MateriPPGPrajab";
import LaporanKemajuan from "../views/Misc/LaporanKemajuan";
import ASN from "../views/Misc/ASN";
import BimTekAsosiasi from "../views/Misc/BimTekAsosiasi";
import PlenoJuklakSeleksiPesertaPM2 from "../views/Misc/PlenoJuklakSeleksiPesertaPM2";
import BimTekKoordinatorPPG from "../views/Misc/BimTekKoordinatorPPG";
import RevisiSuratPernyataanG14 from "../views/Misc/RevisiSuratPernyataanG14";
import PesertaGDM from "../views/Misc/PesertaGDM";
import PitchdeckLPTK from "../views/Misc/PitchdeckLPTK";
import PitchdeckAsosiasi from "../views/Misc/PitchdeckAsosiasi";
import CeritaPraktisiMengajar from "../views/Misc/CeritaPraktisiMengajar";
import SPBatalPraktisi from "../views/Misc/SPBatalPraktisi";
import SPBatalPT from "../views/Misc/SPBatalPT";
import SPUndurDiri from "../views/Misc/SPUndurDiri";
import SPUndurDiriReviewer from "../views/Misc/SPUndurDiriReviewer";
import RevisiLogbookSPTJM from "../views/Misc/RevisiLogbookSPTJM";
import PanduanTutupKolab from "../views/Misc/PanduanTutupKolab";
import FormPengajuan from "../views/Misc/FormPengajuan";
import BimtekPPG from "../views/Misc/BimtekPPG";
import SurveyMahasiswa2022 from "../views/Misc/SurveyMahasiswa2022";
import OnboardingPPG from "../views/Misc/OnboardingPPG";
import ReactGA from "react-ga";
import PanduanTeknisOnboardingPPG from "../views/Misc/PanduanTeknisOnboardingPPG";
import RekamanOnboardingPPG from "../views/Misc/RekamanOnboardingPPG";
import DigitalOccupation from "../views/Misc/DigitalOccupation";
import DataPMg1Ppg from "../views/Misc/DataPMg1Ppg";
import KelengkapanDokumenKeuanganPM from "../views/Misc/KelengkapanDokumenKeuanganPM";
import GolonganPraktisiASNPNS from "../views/Misc/GolonganPraktisiASNPNS";
import KelengkapanDokumenPencairanDana from "../views/Misc/KelengkapanDokumenPencairanDana";
import RecordingKelengkapanDokumenPencairanDana from "../views/Misc/RecordingKelengkapanDokumenPencairanDana";
import StatusPencairan from "../views/Misc/StatusPencairan";
import KelengkapanDokumenKeuanganPM2 from "../views/Misc/KelengkapanDokumenKeuanganPM2";
import PengumumanAkhirPenutupanPelaksanaanKolaborasi from "../views/Misc/PengumumanAkhirPenutupanPelaksanaanKolaborasi";
import AsistensiKolaborasiPM from "../views/Misc/AsistensiKolaborasiPM";
import PencairanReviewer from "../views/Misc/PencairanReviewer";
import BimtekBAPLogbookDouble from "../views/Misc/BimtekBAPLogbookDouble";
import ApresiasiKolaborasiPM from "../views/Misc/ApresiasiKolaborasiPM";
import RevisiSertifikat from "../views/Misc/RevisiSertifikat";
import SarasehanPraktisiPM22 from "views/Misc/SarasehanPraktisiPM22";
import SoftLaunchingPM23LLDikti from "views/Misc/SoftLaunchingPM23LLDikti";
import SoftLaunchingPM23AsosiasiPraktisi from "views/Misc/SoftLaunchingPM23AsosiasiPraktisi";
import SoftLaunchingPM23Vokasi from "views/Misc/SoftLaunchingPM23Vokasi";
import HandbookPraktisi2023 from "views/Misc/HandbookPraktisi2023";
import HandbookPerguruanTinggi2023 from "views/Misc/HandbookPerguruanTinggi2023";
import HandbookReviewer2023 from "views/Misc/HandbookReviewer2023";
import PitchDeckPM2023 from "views/Misc/PitchDeckPM2023";

const loggedIn = true;

const Router = function () {
  const navigate = useNavigate();
  const [isInitialized, setIsInitialized] = useState(false);
  const location = useLocation();

  const path = window.location.pathname;

  useEffect(() => {
    if (!isInitialized) {
      ReactGA.initialize(process.env.REACT_APP_GA_TRACKING_ID);
      setIsInitialized(true);
    }
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [isInitialized, location]);

  if (loggedIn && path === "/login") navigate("/");
  else if (!loggedIn && path !== "/login") navigate("/login");

  return (
    <>
      <Routes>
        <Route path="/" element={<MainLanding />}>
          <Route index element={<Landing />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/kebijakan-privasi" element={<KebijakanPrivasi />} />
          <Route path="/syarat-dan-ketentuan" element={<SyaratDanKetentuan />} />
          <Route path="/pendaftaran-perguruan-tinggi" element={<PendaftaranPT />} />
          <Route path='/bimtekpendaftaranpt' element={<BimtekPendaftaranPT />} />
          <Route path='/bimtekdashboardpt' element={<BimtekDashboardPT />} />
          <Route path='/bimtekreviewer' element={<BimtekReviewer />} />
          <Route path='/deckbimtekpt' element={<DeckBimtekPT />} />
          <Route path='/deckperguruantinggi' element={<DeckPerguruanTinggi />} />
          <Route path='/deckbimtekreviewer' element={<DeckBimtekReviewer />} />
          <Route path='/deckonboardingpraktisi' element={<DeckOnboardingPraktisi />} />
          <Route path='/panduanteknispt' element={<PanduanTeknisPT />} />
          <Route path='/panduanteknisreviewer' element={<PanduanTeknisReviewer />} />
          <Route path='/onepager' element={<OnePager />} />
          <Route path='/deckpraktisi' element={<DeckPraktisi />} />
          <Route path="/dalam-pengembangan" element={<UnderDevelopment />} />
          <Route path="/bukupanduan" element={<BukuPanduan />} />
          <Route path="/InfoReviewer" element={<InfoReviewer />} />
          <Route path="/info-session" element={<InfoSession />} />
          <Route path="/info-session-pt" element={<InfoSessionPT />} />
          <Route path="/seripraktisipilihan" element={<SeriPraktisi />} />
          <Route path="/bukuteknispraktisi" element={<BukuTeknisPraktisi />} />
          <Route path="/bukuonboardingpraktisi" element={<BukuOnboardingPraktisi />} />
          <Route path="/panduanteknisonboarding" element={<PanduanTeknisOnboarding />} />
          <Route path="/pendaftaranpraktisi" element={<PendaftaranPraktisi />} />
          <Route path="/registrasi-praktisi" element={<RegistrasiPraktisi />} />
          <Route path="/revisiproposal" element={<RevisiProposal />} />
          <Route path="/RevisiSertifikat" element={<RevisiSertifikat />} />
          <Route path="/FGDMonevPM" element={<URLRoutingA />} />
          <Route path="/VideoRecordingGDM" element={<URLRoutingB />} />
          <Route path="/sosialisasi-praktisimengajar-ptma" element={<URLRoutingC />} />
          <Route path="/PlenoJuklakSeleksiPesertaPM" element={<URLRoutingD />} />
          <Route path="/BimtekPPGPrajab21Okt" element={<URLRoutingE />} />
          <Route path="/BimtekGelPPG" element={<URLRoutingF />} />
          <Route path="/BimtekGelPPG2" element={<URLRoutingG />} />
          <Route path="/reviewer-pt" element={<ReviewerPT />} />
          <Route path="/BimTekFGDReviewer" element={<BimtekFGDReviewer />} />
          <Route path="/InfoPM" element={<InfoPM />} />
          <Route path="/info" element={<Info />} />
          <Route path="/datapraktisi" element={<DataPraktisi />} />
          <Route path="/pesertag1" element={<PesertaG1 />} />
          <Route path="/datapraktisig2" element={<DataPraktisiG2 />} />
          <Route path="/pesertag2" element={<PesertaG2 />} />
          <Route path="/seleksig2" element={<SeleksiG2 />} />
          <Route path="/datapraktisig3" element={<DataPraktisiG3 />} />
          <Route path="/revisiproposalg3" element={<RevisiProposalG3 />} />
          <Route path="/pesertag3" element={<PesertaG3 />} />
          <Route path="/datapraktisig4" element={<DataPraktisiG4 />} />
          <Route path="/skreviewer1" element={<SKREviewer1 />} />
          <Route path="/pesertabimtekpraktisimengajar" element={<PesertaBimtekPraktisiMengajar />} />
          <Route path="/suratpernyataan" element={<SuratPernyataan />} />
          <Route path="/templatesuratpernyataan" element={<TemplateSuratPernyataan />} />
          <Route path="/hapuskelas" element={<HapusKelas />} />
          <Route path="/telegrampraktisimengajar" element={<TelegramPraktisiMengajar />} />
          <Route path="/hubungibantuan" element={<HubungiKami />} />
          <Route path="/datapraktisig3.1" element={<DataPraktisiG31 />} />
          <Route path="/BimtekProposalGDM1" element={<BimtekProposalGDM1 />} />
          <Route path="/BimtekProposalGDM2" element={<BimtekProposalGDM2 />} />
          <Route path="/MateriBimtekGDM1" element={<MateriBimtekGDM1 />} />
          <Route path="/MateriBimtekGDM2" element={<MateriBimtekGDM2 />} />
          <Route path="/BimtekKhususGDMSesi2" element={<BimtekKhususGDMSesi2 />} />
          <Route path="/BimtekKhususGDMSesi3" element={<BimtekKhususGDMSesi3 />} />
          <Route path="/HandbookKolaborasi" element={<HandbookKolaborasi />} />
          <Route path="/MateriTeknisPPG" element={<MateriTeknisPPG />} />
          <Route path="/ContohSuratPernyataan" element={<ContohSuratPernyataan />} />
          <Route path="/dataPMG1-3" element={<DataPMG13 />} />
          <Route path="/PPG-PB-lanjutan" element={<PpgPb />} />
          <Route path="/MateriPPGPrajab" element={<MateriPPGPrajab />} />
          <Route path="/LaporanKemajuan" element={<LaporanKemajuan />} />
          <Route path="/ASN" element={<ASN />} />
          <Route path="/BimTekKoordinatorPPG" element={<BimTekKoordinatorPPG />} />
          <Route path="/PlenoJuklakSeleksiPesertaPM-2" element={<PlenoJuklakSeleksiPesertaPM2 />} />
          <Route path="/BimTekAsosiasi" element={<BimTekAsosiasi />} />
          <Route path="/revisi-suratpernyataanG1-4" element={<RevisiSuratPernyataanG14 />} />
          <Route path="/pesertaGDM" element={<PesertaGDM />} />
          <Route path="/PitchdeckLPTK" element={<PitchdeckLPTK />} />
          <Route path="/PitchdeckAsosiasi" element={<PitchdeckAsosiasi />} />
          <Route path="/CeritaPraktisiMengajar" element={<CeritaPraktisiMengajar />} />
          <Route path="/SPBatalPT" element={<SPBatalPT />} />
          <Route path="/SPBatalPraktisi" element={<SPBatalPraktisi />} />
          <Route path="/SPUndurDiri" element={<SPUndurDiri />} />
          <Route path="/SPUndurDiriReviewer" element={<SPUndurDiriReviewer />} />
          <Route path="/RevisiLogbookSPTJM" element={<RevisiLogbookSPTJM />} />
          <Route path="/PanduanTutupKolab" element={<PanduanTutupKolab />} />
          <Route path="/FormPengajuan" element={<FormPengajuan />} />
          <Route path="/BimtekPPG" element={<BimtekPPG />} />
          <Route path="/SurveyMahasiswa2022" element={<SurveyMahasiswa2022 />} />
          <Route path="/OnboardingPPG" element={<OnboardingPPG />} />
          <Route path="/panduanteknisonboardingppg" element={<PanduanTeknisOnboardingPPG />} />
          <Route path="/RekamanOnboardingPPG" element={<RekamanOnboardingPPG />} />
          <Route path="/digitaloccupation2022" element={<DigitalOccupation />} />
          <Route path="/dataPMg1-ppg" element={<DataPMg1Ppg />} />
          <Route path="/KelengkapanDokumenKeuanganPM" element={<KelengkapanDokumenKeuanganPM />} />
          <Route path="/GolonganPraktisiASN-PNS" element={<GolonganPraktisiASNPNS />} />
          <Route path="/KelengkapanDokumenPencairanDana" element={<KelengkapanDokumenPencairanDana />} />
          <Route path="/RecordingKelengkapanDokumenPencairanDana" element={<RecordingKelengkapanDokumenPencairanDana />} />
          <Route path="/statuspencairan" element={<StatusPencairan />} />
          <Route path="/KelengkapanDokumenKeuanganPM2" element={<KelengkapanDokumenKeuanganPM2 />} />
          <Route path="/PengumumanAkhirPenutupanPelaksanaanKolaborasi" element={<PengumumanAkhirPenutupanPelaksanaanKolaborasi />} />
          <Route path="/asistensikolaborasiPM" element={<AsistensiKolaborasiPM />} />
          <Route path="/pencairanreviewer" element={<PencairanReviewer />} />
          <Route path="/BimtekBAPLogbookDouble" element={<BimtekBAPLogbookDouble />} />
          <Route path="/ApresiasiKolaborasiPM" element={<ApresiasiKolaborasiPM />} />
          <Route path="/SarasehanPraktisiPM22" element={<SarasehanPraktisiPM22 />} />
          <Route path="/SoftLaunchingPM2023-LLDIKTI" element={<SoftLaunchingPM23LLDikti />} />
          <Route path="/SoftLaunchingPM2023-Praktisi" element={<SoftLaunchingPM23AsosiasiPraktisi />} />
          <Route path="/SoftLaunchingPM2023-Vokasi" element={<SoftLaunchingPM23Vokasi />} />
          <Route path="/HandbookPraktisi2023" element={<HandbookPraktisi2023 />} />
          <Route path="/HandbookPerguruanTinggi2023" element={<HandbookPerguruanTinggi2023 />} />
          <Route path="/HandbookReviewer2023" element={<HandbookReviewer2023 />} />
          <Route path="/PitchDeckPM2023" element={<PitchDeckPM2023 />} />
        </Route>
      </Routes>
    </>
  );
};

export default Router;

