function SPUndurDiriReviewer(){
    window.location.href = 'https://www.dropbox.com/s/5f7fl4fjukm5ymy/PRAKTISI%20MENGAJAR%20-%20PENGUNDURAN_DIRI_REVIEWER.docx?dl=0'
    return (
        <></>
    )
}

export default SPUndurDiriReviewer;




