import {Container, Grid, Row, Col, Button} from "rsuite";
import './syaratDanKetentuan.scss'

function SyaratDanKetentuan(){
    return (
        <Container style={{padding: 0, background: '#FFF'}}>
            <div style={{backgroundColor: '#F5F5F5', color: "#25282B"}}>
                <Grid className="landing-grid">
                    <Row gutter={24}>
                        <Col className='mb-1' xs={24}>
                            <h2 className="landing-title font-bossaBold">
                                Syarat Dan Ketentuan
                            </h2>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Grid className="landing-grid py-6">
                <Row gutter={24}>
                    <Col xs={24} className="pb-2">
                      <h6 className="mb-2">Syarat dan Ketentuan Penggunaan Website Program Praktisi Mengajar</h6>
                      <p>Selamat datang di website Praktisi Mengajar (“Website ini” atau “Website Kami” atau "Website Praktisi Mengajar" tergantung pada konteksnya). Harap membaca Syarat dan Ketentuan Penggunaan Website Praktisi Mengajar ini (“Syarat dan Ketentuan”) dengan cermat sebelum Anda menggunakan Website ini dan/atau melakukan pendaftaran dan membuka “akun”. Syarat dan Ketentuan ini mengatur penggunaan seluruh fungsi yang terdapat pada Website ini, termasuk namun tidak terbatas pada: (i) akses dan penggunaan Anda atas Website ini, dan/atau (iii) akses dan penggunaan Anda atas Website ini yang tertaut atau merujuk pada Syarat dan Ketentuan ini, dan berisi informasi penting tentang hak, upaya hukum, dan kewajiban hukum Anda.
                          <br/><br/>Syarat dan Ketentuan ini merupakan perjanjian yang mengikat secara hukum antara pengguna Website Praktisi Mengajar (“Anda”) dan Program Praktisi Mengajar (“Kami” atau “Praktisi Mengajar” tergantung pada konteksnya). Dengan mengakses atau menggunakan Website ini, Anda secara sadar menyetujui Syarat dan Ketentuan ini, baik Anda menjadi pengguna terdaftar dari Website ini atau pun tidak. Anda menyatakan dan menjamin bahwa Anda telah berusia minimal 18 (delapan belas) tahun atau sudah menikah dan tidak berada di bawah perwalian atau pengampuan, dan Anda secara hukum memiliki kapasitas dan berhak untuk mengikatkan diri pada Syarat dan Ketentuan ini.
                          <br/><br/>Pedoman Program, Kebijakan Privasi, dan kebijakan, aturan, atau pedoman lain Kami yang mungkin berlaku untuk pelayanan atau fitur tertentu di Website ini juga dimasukkan ke dalam Syarat dan Ketentuan ini. Dengan mengunjungi atau menggunakan Website Praktisi Mengajar, Anda secara tegas menyetujui Syarat dan Ketentuan ini, dan perubahannya dari waktu ke waktu.
                      </p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">Kebijakan Privasi</h6>
                      <p>Anda menyatakan bahwa Anda telah membaca dan memahami kebijakan privasi Kami ("Kebijakan"), yang tersedia di <a href="/kebijakan-privasi">sini</a>. Kami dapat mengungkapkan informasi tentang Anda kepada pihak ketiga, jika Kami memiliki keyakinan dengan itikad baik bahwa pengungkapan tersebut secara wajar diperlukan untuk: (i) mengambil tindakan terkait dugaan kegiatan ilegal; (ii) menegakkan atau menerapkan Syarat dan Ketentuan atau kebijakan lain; (iii) mematuhi perintah hukum atau badan pemerintah lain, termasuk namun tidak terbatas pada surat perintah penggeledahan, panggilan pengadilan, undang-undang, proses peradilan atau perintah hukum lain yang diberikan kepada Kami; dan/atau (iv) melindungi hak, reputasi, dan properti Kami atau milik pengguna, afiliasi, dan/atau publik Kami.</p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">Daftar Isi</h6>
                      <ol className="daftar-isi">
                        <li>Hubungan</li>
                        <li>Perubahan</li>
                        <li>Penjelasan Program</li>
                        <li>Akun Praktisi Mengajar</li>
                        <li>Informasi Pribadi</li>
                        <li>Jangka Waktu dan Penghentian Penggunaan</li>
                        <li>Korespondensi</li>
                      </ol>
                    </Col>
                    <hr />
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">1. Hubungan</h6>
                      <p>Anda secara sadar memahami dan menyetujui bahwa (i) Praktisi Mengajar bukan merupakan pihak dalam perjanjian yang dibuat antara mahasiswa dan dosen/lektor/tutor; (ii) Praktisi bukan merupakan karyawan atau tenaga kerja Praktisi Mengajar; dan (iii) Praktisi Mengajar tidak memiliki kewajiban untuk mempertahankan Praktisi untuk tetap dapat memberikan pengajaran di perguruan tinggi tertentu. Untuk itu, Praktisi Mengajar tidak memiliki kendali atas perilaku mahasiswa, dosen/lektor/tutor, Anggota, atau pengguna lain dari Website ini. 
                        <br/><br/>Anda setuju untuk mengganti kerugian dan membebaskan Praktisi Mengajar, pejabat, pengurus, penerus kepentingan, dan afiliasinya dari segala tuntutan, kerugian, kewajiban, klaim atau pengeluaran (termasuk biaya pengacara), yang dibuat terhadap Praktisi Mengajar oleh pihak ketiga mana pun yang muncul akibat atau timbul dari atau sehubungan dengan penggunaan Website ini oleh Anda.
                      </p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">2. Perubahan</h6>
                      <p>Praktisi Mengajar berhak, atas kebijakan Kami sendiri, mengubah Syarat dan Ketentuan Website Praktisi Mengajar setiap saat dan dengan atau tanpa pemberitahuan sebelumnya. Perubahan Syarat dan Ketentuan ini Website ini otomatis berlaku pada tanggal perubahan dilakukan.</p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">3. Penjelasan Program</h6>
                      <p>Kegiatan Praktisi Mengajar adalah sebagaimana yang dijelaskan pada <a href="/bukupanduan">panduan ini</a>.</p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">4. Akun Praktisi Mengajar</h6>
                      <p>Beberapa fungsi dalam Website ini memerlukan pendaftaran Akun dan Anda dapat memilih identifikasi pengguna yang unik ("User ID") dan kata sandi, dengan memberikan informasi pribadi tertentu. Jika Anda memilih User ID yang Praktisi Mengajar, yang atas kebijakan Kami, Kami anggap menyinggung atau tidak pantas, Kami berhak menangguhkan atau menghentikan Akun Anda. Anda setuju untuk: (i) menggunakan kata sandi yang kuat dan menjaga kerahasiaannya; (ii) tidak mentransfer satu atau lebih bagian dari akun Anda (User ID atau kata sandi atau keduanya secara bersama-sama); dan (iii) mematuhi segala ketentuan Praktisi Mengajar yang diuraikan dalam <a href="/bukupanduan">panduan ini</a>.
                        <br/><br/>Anda menyatakan bahwa dengan mengklik "Daftar", "Saya Tertarik" atau kegiatan yang serupa mendaftar, mengakses, atau menggunakan Website ini, Anda setuju untuk menandatangani perjanjian yang secara hukum mengikat Anda dengan Praktisi Mengajar (termasuk ketika Anda menggunakan fitur pendaftaran di Website ini atas nama perusahaan atau institusi tertentu).  
                        <br/><br/>Anda dapat menutup atau mengakhiri Akun Anda dengan memberi tahu Kami secara tertulis <a href={`${process.env.REACT_APP_CONTACT_US_URL}`}>(melalui email Support Praktisi Mengajar)</a> yang berisi maksud Anda untuk menutup atau mengakhiri Akun Anda. Anda membebaskan Praktisi Mengajar atas semua dan/atau potensi klaim berdasarkan tindakan penghentian Akun Anda tersebut oleh Kami.
                      </p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">5. Informasi Pribadi</h6>
                      <p>Pengumpulan, penyimpanan, pengolahan, penggunaan dan pembagian informasi pribadi Anda, seperti nama, alamat, alamat surat elektronik, nomor identitas, nomor telepon, informasi finansial, informasi perpajakan, deskripsi pribadi, foto, dan data lain yang Anda berikan ketika Anda membuka Akun tunduk pada <a href="/kebijakan-privasi">kebijakan privasi kami</a>, yang merupakan bagian yang tidak terpisahkan dari Syarat dan Ketentuan ini.</p>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">6. Jangka Waktu dan Penghentian Penggunaan</h6>
                      <ol className="list-prefix-6">
                        <li>Kebebasan untuk menggunakan Website ini dan seluruh fitur yang tersedia berlaku hingga diakhiri. Penggunaan Website berakhir sebagaimana diatur dalam Syarat dan Ketentuan ini atau jika Anda gagal mematuhi persyaratan atau ketentuan dari Syarat dan Ketentuan ini. Dalam hal demikian, Praktisi Mengajar dapat melakukan penghentian tersebut dengan atau tanpa pemberitahuan kepada Anda.</li>
                        <li>Anda setuju untuk tidak:</li>
                        <ol type="a">
                          <li>mengunggah, mengirimkan, mentransmisikan, dan/atau menyediakan konten apa pun yang melanggar hukum, berbahaya, mengancam, kasar, melecehkan, memfitnah, vulgar, cabul, mengganggu privasi orang lain, ungkapan kebencian yang memuat tetapi tidak terbatas pada unsur suku, agama, ras, dan antar golongan, atau pernyataan lain yang tidak pantas.</li>
                          <li>dengan sengaja meneruskan data, mengirim, dan/atau mengunggah materi yang mengandung virus, trojan, worm, logic bomb, keystroke loggers, spyware, adware, maupun program berbahaya lain atau kode komputer sejenis yang dirancang untuk memberikan efek merugikan terhadap pengoperasian perangkat lunak atau perangkat keras apa pun.</li>
                          <li>menggunakan Website ini untuk mengunggah konten dengan maksud menyamar sebagai orang atau entitas mana pun yang bukan diri sendiri, atau salah menggambarkan afiliasi Anda dengan seseorang atau suatu entitas mana pun.</li>
                        </ol>
                      </ol>
                    </Col>
                    <Col xs={24} className="py-2">
                      <h6 className="mb-2">7. Korespondensi</h6>
                      <p>Anda dapat menghubungi kami melalui <a href={`${process.env.REACT_APP_CONTACT_US_URL}`}>Support Praktisi Mengajar</a>.</p>
                    </Col>
                </Row>
            </Grid>
        </Container>
    )
}

export default SyaratDanKetentuan;
