import axios from "axios";
import ShowMessageInfo from "./messageToaster";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const getConfig = () => {
  return {
    headers: {
      Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiZW1haWwiOiJ1c2VyQGdtYWlsLmNvbSIsImlhdCI6MTY0NTYwNjkzOH0.2sNHO55kbaq_MOKwNNxa4cDK8vEfjOnBQYsFMqTyTRE`,
    },
  };
};

api.interceptors.request.use((config) => {
  return config;
});

api.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    let message = "";

    if (
      error &&
      error.response &&
      error.response.data &&
      error.response.data.error &&
      typeof error.response.data.error === "string"
    ) {
      message = error.response.data.error;
    } else if (error.response.statusText) {
      message = error.response.statusText;
    }

    if (message) {
      // Notification.open({
      //   message: message,
      //   type: 'is-danger',
      // })
    }

    // if (messsage) store.dispatch('updateErrorMessage', messsage)
    //
    // if (error.response.status === 401) store.dispatch('auth/logout')
    return Promise.reject(error);
  }
);

const get = (url, data) => {
  let result = {
    data: {},
    message: "",
    error: false,
    status: null,
  };

  let config = getConfig();
  config.params = data;

  return new Promise((resolve, reject) => {
    api
      .get(url, config)
      .then((res) => {
        result.data = res.data.data;
        result.message = res.data.message;
        resolve(result);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          result.data = err.response.data.data;
          result.message = err.response.data.message;
        }
        result.error = true;
        ShowMessageInfo.error(result?.message ?? "Something went wrong");
        reject(result);
      });
  });
};

const post = (url, data) => {
  let result = {
    data: {},
    message: "",
    error: false,
  };

  return new Promise((resolve, reject) => {
    api
      .post(url, data, getConfig())
      .then((res) => {
        result.data = res.data.data;
        result.message = res.data.message;
        resolve(result);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          result.data = err.response.data.data;
          result.message = err.response.data.message;
        }
        result.error = true;
        ShowMessageInfo.error(result?.message ?? "Something went wrong");
        reject(result);
      });
  });
};

const put = (url, data) => {
  let result = {
    data: {},
    message: "",
    error: false,
  };

  return new Promise((resolve, reject) => {
    api
      .put(url, data, getConfig())
      .then((res) => {
        result.data = res.data.data;
        result.message = res.data.message;
        resolve(result);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          result.data = err.response.data.data;
          result.message = err.response.data.message;
        }
        result.error = true;
        ShowMessageInfo.error(result?.message ?? "Something went wrong");
        reject(result);
      });
  });
};

const patch = (url, data) => {
  let result = {
    data: {},
    message: "",
    error: false,
  };

  return new Promise((resolve, reject) => {
    api
      .patch(url, data, getConfig())
      .then((res) => {
        result.data = res.data.data;
        result.message = res.data.message;
        resolve(result);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          result.data = err.response.data.data;
          result.message = err.response.data.message;
        }
        result.error = true;
        ShowMessageInfo.error(result?.message ?? "Something went wrong");
        reject(result);
      });
  });
};

const destroy = (url) => {
  let result = {
    data: {},
    message: "",
    error: false,
  };

  return new Promise((resolve, reject) => {
    api
      .delete(url, getConfig())
      .then((res) => {
        result.data = res.data.data;
        result.message = res.data.message;
        resolve(result);
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          result.data = err.response.data.data;
          result.message = err.response.data.message;
        }
        result.error = true;
        ShowMessageInfo.error(result?.message ?? "Something went wrong");
        reject(result);
      });
  });
};

const Api = {
  get,
  post,
  put,
  patch,
  destroy,
};

export default Api;
