import {Grid, Row, Col, IconButton, Button} from 'rsuite';
import './landingFooter.scss';
import {useLowerBreakpoint} from 'lib/breakpoint';
import tutWuriHandayani from 'assets/tut-wuri-handayani.png';
import { Icon } from '@rsuite/icons';
import {ReactComponent as TwitterIcon} from 'assets/twitter.svg';
import {ReactComponent as FacebookIcon} from 'assets/facebook.svg';
import {ReactComponent as InstagramIcon} from 'assets/instagram.svg';
import {ReactComponent as YoutubeIcon} from 'assets/youtube.svg';
import {ReactComponent as WebsiteIcon} from 'assets/website.svg';
import {useNavigate} from 'react-router-dom';

function LandingFooter(){
    const navigate = useNavigate();
    return (
        <div className="landing-footer" style={{paddingTop: useLowerBreakpoint("md")?'2.5rem':'3rem'}}>
            <div className='landing-grid rs-grid-container'>
                <img className='mr-3 mb-2' src={ tutWuriHandayani } alt="tutwurihandayani-logo" height={120} width={120}></img>
                <Grid className="mx-0 text-white">
                    <Row gutter={24} style={{marginBottom: useLowerBreakpoint("md")?'2.5rem':'5rem'}}>
                        <Col xs={24} md={12}>
                            <p className='mb-2 font-bold footer-title'>
                                Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia
                                {/* <br/>
                                Direktorat Jenderal Pendidikan Tinggi */}
                            </p>
                            <p className='mb-3'>Jl. Jenderal Sudirman, Senayan, Jakarta 10270</p>
                        </Col>
                        <Col xs={24} md={12}>
                            <p className='font-bold footer-title mb-2'>Navigasi</p>
                            <Button className='mb-1 text-white p-0' appearance='link' href="/#home">Beranda</Button><br/>
                            <Button className='mb-1 text-white p-0' appearance='link' href="/#about">Tentang Praktisi Mengajar</Button><br/>
                            {/* <Button className='mb-1 text-white p-0' appearance='link' target="_blank" href="https://pusatinformasi.praktisimengajar.id">Pusat Informasi</Button><br/> */}
                            <Button href="/syarat-dan-ketentuan" className='mb-1 text-white p-0' appearance='link'>Syarat dan Ketentuan</Button><br/>
                            <Button href="/kebijakan-privasi" className='mb-1 text-white p-0' appearance='link'>Ketentuan Privasi</Button><br/>
                            {/* <Button className='mb-1 text-white p-0' appearance='link'>Berita</Button><br/>
                            <Button className='mb-1 text-white p-0' appearance='link'>Blog</Button><br/> */}
                            <Button className='mb-1 text-white p-0' target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}`} appearance='link'>Masuk/Daftar</Button><br/>
                            <Button className='mb-1 text-white p-0' target="_blank" href={`${process.env.REACT_APP_CONTACT_US_URL}`} appearance='link'>Hubungi Bantuan</Button><br/>
                            <Button className='text-white p-0' target="_blank" href="/bukupanduan" appearance='link'>Buku Panduan</Button>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col xs={24} md={12} className='mb-5'>
                            <IconButton className="icon-social" target="_blank" href="https://twitter.com/PMengajar" icon={<Icon as={TwitterIcon} style={{fontSize: '24px'}} />} appearance="subtle" circle style={{marginLeft: '-10px'}}/>
                            <IconButton className="icon-social" target="_blank" href="https://instagram.com/praktisimengajar" icon={<Icon as={InstagramIcon} style={{fontSize: '24px'}} />} appearance="subtle" circle />
                            {/* <IconButton icon={<Icon as={YoutubeIcon} style={{fontSize: '24px'}} />} appearance="subtle" circle /> */}
                            <IconButton className="icon-social" target="_blank" href="https://www.facebook.com/Praktisi-Mengajar-Kampus-Merdeka-100234405952680" icon={<Icon as={FacebookIcon} color="#FFF" style={{fontSize: '24px'}} />} appearance="subtle" circle />
                            {/* <IconButton icon={<Icon as={WebsiteIcon} style={{fontSize: '24px'}} />} appearance="subtle" circle /> */}
                        </Col>
                        <Col xs={24} md={12}>
                            <p>Hak Cipta 2022 oleh Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi RI</p>
                            {/* <p>Foto oleh ITS, ITERA, and UI</p> */}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
} 

export default LandingFooter;
