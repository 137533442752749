import { Container, Grid } from "rsuite";
import LandingBanner from "./sections/LandingBanner";
import LandingTentang from "./sections/LandingTentang";
import LandingModel from "./sections/LandingModel";
import LandingSyarat from "./sections/LandingSyarat";
import LandingTimeline from "./sections/LandingTimeline";
import LandingKolaborasi from "./sections/kolaborasi/LandingKolaborasi";
import LandingSubscribe from "./sections/LandingSubscribe";
import "./landing.scss";
import "./base.scss";

function Landing() {
  return (
    <Container style={{padding: 0}} className="landing">
        <LandingBanner />
        <LandingTentang />
        {/* <LandingModel /> */}
        <LandingKolaborasi />
        {/* <LandingTimeline /> */}
        <LandingSyarat />
        {/* <LandingSubscribe /> */}
    </Container>
  );
}

export default Landing;
