function FormPengajuan(){
    window.location.href = 'https://airtable.com/shru3hki87LwHXLqv'
    return (
        <></>
    )
}

export default FormPengajuan;


