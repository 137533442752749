// import {Container, Grid, Row, Col, Button} from "rsuite";
// import './pendaftaranPT.scss'
function PendaftaranPT(){
    window.location.href = `${process.env.REACT_APP_DASHBOARD_URL}/auth/registrasi`
    return (
        <></>
    )
    // return (
    //     <Container style={{padding: 0, background: '#FFF'}}>
    //         <div style={{backgroundColor: '#F5F5F5', color: "#25282B"}}>
    //             <Grid className="landing-grid">
    //                 <Row gutter={24}>
    //                     <Col className='mb-1' xs={24}>
    //                         <h2 className="font-bossaBold">
    //                             Pendaftaran Perguruan Tinggi
    //                         </h2>
    //                     </Col>
    //                 </Row>
    //             </Grid>
    //         </div>
    //         <Grid className="landing-grid py-6">
    //             <Row gutter={24}>
    //                 <Col xs={24} className="pb-2">
    //                   <p>Saat ini, pendaftaran perguruan tinggi belum dibuka.</p>
    //                 </Col>
    //                 <Col xs={24} className="py-2">
    //                   <p>
    //                     Silakan klik tautan <a data-id="XP13452671" href="https://www.addevent.com/event/XP13452671" target="_blank" rel="nofollow">Ingatkan Saya</a> untuk mengatur pengingat waktu pembukaan Program langsung di kalender elektronik Anda (Google Calendar, Outlook Calendar, Yahoo! Calendar).
    //                   </p>
    //                 </Col>
    //                 <Col xs={24} className="py-2">
    //                   <div className="d-flex">
    //                     {/* test */}
    //                     {/* <Button color="primary" className="mr-1 mb-1">Senin, 11 April 2022 | 09:00 - 12:00</Button>
    //                     <Button color="primary" className="mr-1 mb-1">Rabu, 13 April 2022 | 09:00 - 12:00</Button>
    //                   </div>
    //                   <div className="d-flex">
    //                     <Button color="primary" className="mr-1 mb-1">Selasa, 11 April 2022 | 09:00 - 12:00</Button> 
    //                     <Button color="primary" className="mr-1 mb-1" target="_blank" disabled>Rabu, 27 April 2022 | 10:00 - 12:00</Button>*/}
    //                     <Button color="primary" className="mr-1 mb-1" target="_blank" href="https://www.addevent.com/event/XP13452671">Ingatkan Saya</Button>
    //                   </div>
    //                 </Col>
    //             </Row>
    //         </Grid>
    //     </Container>
    // )
}

export default PendaftaranPT;
