function RevisiLogbookSPTJM(){
    window.location.href = 'https://airtable.com/shrTUT9MNiMUkZPNR'
    return (
        <></>
    )
}

export default RevisiLogbookSPTJM;


