function PesertaG1(){
    window.location.href = 'https://drive.google.com/file/d/1fvEIhWPkj6M9ncNigj07cC-tOqmI38qc/view?usp=sharing'
    return (
        <></>
    )
}

export default PesertaG1;


