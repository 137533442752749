import {Container, Grid, Row, Col, Button, Table} from "rsuite";
import LandingAccordion from "views/Landing/components/LandingAccordion";
import {useLowerBreakpoint} from "lib/breakpoint";
import LandingTabs from "views/Landing/components/LandingTabs";
import LandingTabItem from "views/Landing/components/LandingTabItem";
import LandingCard from "views/Landing/components/LandingCard";
import "./FAQ.scss"
// import LandingNavBar from "views/Landing/sections/LandingNavBar";

function FAQ(){
    return (
        <Container style={{padding: 0, background: '#FFF'}}>
            <div style={{backgroundColor: '#F5F5F5', color: "#25282B"}}>
                <Grid className="landing-grid">
                    <Row gutter={24}>
                        <Col className='mb-1' xs={24}>
                            <h2 className="landing-title font-bossaBold">
                                Pertanyaan yang Sering Ditanyakan
                            </h2>
                        </Col>
                        <Col xs={24}>
                            <p>
                                Lihat jawaban atas pertanyaan Anda di sini.
                            </p>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Grid className="landing-grid py-6">
                <Row gutter={24}>
                    <Col xs={24}>
                        <LandingTabs>
                            <LandingTabItem title={'Umum'}>
                                <Col xs={24} md={16}>
                                    {/* <LandingAccordion title={'Untuk list daftar perguruan tinggi penyelenggara Praktisi Mengajar dan mata kuliah yang ditawarkan dapat diakses di mana ya?'}>
                                        <p>
                                            -
                                        </p>
                                    </LandingAccordion> */}
                                    <LandingAccordion title={'Saya tidak mendapat pesan verifikasi akun, sudah saya coba berulang kali namun tetap tidak bisa. Bagaimana agar saya bisa verifikasi akun?'}>
                                        <p>
                                            Ajukan laporan dengan menghubungi layanan bantuan kami dengan mengisi form hubungi kami di <a target="_blank" href={`mailto:bantuan@praktisimengajar.id`}>bantuan@praktisimengajar.id</a>.
                                        </p>
                                    </LandingAccordion>
                                    {/* <LandingAccordion title={'Bagaimana cara saya mengedit data saya, misal nomor telepon saya berubah?'}>
                                        <p>
                                            Ajukan laporan dengan menghubungi layanan bantuan kami dengan mengisi form hubungi kami di <a target="_blank" href={`mailto:bantuan@praktisimengajar.id`}>bantuan@praktisimengajar.id</a>.
                                        </p>
                                    </LandingAccordion> */}
                                    <LandingAccordion title={'Bagaimana jika saya tidak mendapatkan konfirmasi diterima/ditolak, dan pemberitahuan lain melalui email saya?'}>
                                        <p>
                                            Ajukan laporan dengan menghubungi layanan bantuan kami dengan mengisi form hubungi kami di <a target="_blank" href={`mailto:bantuan@praktisimengajar.id`}>bantuan@praktisimengajar.id</a>.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana jika ada kendala dan saya ingin menghubungi admin Praktisi Mengajar?'}>
                                        <p>
                                        Ajukan laporan dengan menghubungi layanan bantuan kami dengan mengisi form hubungi kami di <a target="_blank" href={`mailto:bantuan@praktisimengajar.id`}>bantuan@praktisimengajar.id</a>. Laporan Anda akan diproses dalam 1x24 jam hari kerja.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem>
                            <LandingTabItem title={'Praktisi'}>
                                <h4 className='font-bossaBold mt-5 mb-3'>
                                    Pendaftaran
                                </h4>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Bagaimana cara mendaftarkan diri sebagai praktisi dalam program ini?'}>
                                        <Col xs={24}>
                                            Praktisi bisa mendaftarkan diri dengan mengisi formulir pendaftaran Praktisi melengkapi persyaratan dokumen secara daring dengan klik tombol <a href={`${process.env.REACT_APP_DASHBOARD_URL}`}>Daftar Sebagai Praktisi</a>
                                        </Col>
                                    </LandingAccordion>
                                    {/* <LandingAccordion title={'Berapa lama proses validasi data dilakukan?'}>
                                        <p>
                                            Proses tersebut akan dilakukan maksimal 5x24 jam pada hari kerja.
                                        </p>
                                    </LandingAccordion> */}
                                    <LandingAccordion title={'Apakah untuk bergabung di Program Praktisi Mengajar ini perlu mengikuti tes?'}>
                                        <p>
                                            Tidak ada tes namun butuh melengkapi data registrasi profil praktisi pada situs resmi Program Praktisi Mengajar untuk memenuhi keseluruhan proses pendaftaran.
                                        </p>
                                    </LandingAccordion>                                    
                                    <LandingAccordion title={'Apa ada batasan skala usaha institusi praktisi untuk terlibat?'}>
                                        <p>
                                            Tidak ada prioritas dan batasan skala usaha institusi praktisi. Prioritas utama adalah pengalaman dari calon praktisi.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah praktisi dapat melakukan pendaftaran secara grup?'}>
                                            <p>Praktisi harus mendaftar per individu, karena ada pertanyaan yang perlu dijawab secara pribadi dan masing-masing individu memiliki keahlian yang berbeda.</p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Siapa yang termasuk praktisi internasional/expat?'}>
                                        <ol className="p-0 px-2 mb-0">
                                            <li>Praktisi WNA yang bertugas di Indonesia. Dokumen identitas yang dibutuhkan adalah passport dan Taxpayer Identification Number (TIN).</li>
                                            <li>Praktisi WNA dan tinggal di negara asalnya. Dokumen identitas yang dibutuhkan adalah passport dan Taxpayer Identification Number (TIN).</li>
                                            <li>Praktisi WNI yang bertugas di luar negeri. Dokumen identitas yang dibutuhkan KTP/Passport dan NPWP</li>
                                        </ol>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Dokumen persyaratan apa yang dibutuhkan bagi praktisi internasional/expat?'}>
                                        <p>Passport untuk pengganti KTP, dan Tax Identification Number (TIN) untuk pengganti NPWP. Khusus praktisi WNI yang bertugas di luar negeri, dianjurkan menggunakan KTP dan NPWP.</p>
                                    </LandingAccordion>
                                    {/* <LandingAccordion title={'Siapakah yang akan terlibat dalam proses reviewer?'}>
                                        <p>
                                            Dosen dan Praktisi dalam bidang Psikologi / MSDM
                                        </p>
                                    </LandingAccordion>   
                                    <LandingAccordion title={'Dari mana mendapatkan informasi mengenai diterima atau ditolaknya bergabung dengan Program Praktisi Mengajar?'}>
                                        <p>
                                            Calon praktisi akan menerima pemberitahuan diterima atau ditolak via email
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah praktisi boleh mengajar secara grup dalam 1 kelas? Atau apakah praktisi boleh mengajar bersama teman praktisi yang dalam satu divisi / perusahaan?'}>
                                        <p>
                                            Setiap praktisi harus memiliki kontrak kolaborasi yang telah dipilih masing-masing, dan penjadwalan serta kegiatan pengajaran di kelas dapat didiskusikan dengan partner dosen yang sudah match.
                                        </p>
                                        <p>
                                            Apabila dalam 1 kelas, dosen bisa match dengan 2 praktisi, maka mengajar secara grup bisa diprogramkan.
                                        </p>
                                    </LandingAccordion>   
                                    <LandingAccordion title={'Siapa yang termasuk praktisi internasional?'}>
                                        <ol className="p-0 px-2 mb-0">
                                            <li>
                                                Praktisi WNA yang bertugas di Indonesia --> passport and TIN
                                            </li>
                                            <li>
                                                Praktisi WNA dan tinggal di negara asalnya --> Passport/ID No.
                                            </li>
                                            <li>
                                                Praktisi WNI yang bertugas di luar negeri --> KTP / Passport
                                            </li>
                                        </ol>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Persyaratan apa yang dibutuhkan bagi praktisi internasional?'}>
                                        <p>
                                            Passport untuk pengganti KTP, dan Tax Identification Number untuk pengganti NPWP.
                                        </p>
                                        <p>
                                            Khusus praktisi WNI yang bertugas di luar negeri, dianjurkan menggunakan KTP dan NPWP.
                                        </p>
                                    </LandingAccordion>  */}
                                </Col>
                                <Col xs={24}>
                                    <h4 className='font-bossaBold mt-5 mb-3'>
                                        Pendanaan
                                    </h4>
                                </Col>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Bagaimana jika saya tidak mempunyai rekening sesuai rekomendasi penyelenggara program? Apakah boleh menggunakan nomor rekening orang lain?'}>
                                        <p>
                                            Nomor rekening harus menggunakan nama calon praktisi yang tertera saat pendaftaran dan tidak bisa digantikan oleh orang lain.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah bisa mencantumkan Bank selain yang direkomendasikan oleh penyelenggara program?'}>
                                        <p>
                                            Saat ini mitra bank untuk kegiatan Kampus Merdeka adalah BRI, maka untuk memudahkan transaksi disarankan menggunakan bank yang sama. Apabila praktisi mencantumkan bank selain BRI, akan ada biaya tambahan transaksi antar bank.
                                        </p>
                                    </LandingAccordion>
                                    {/* <LandingAccordion title={'Apa saja persyaratan untuk pencairan honor praktisi?'}>
                                        <ol className="p-0 px-2 mb-0">
                                            <li>
                                                SK Praktisi yang diterbitkan oleh Kemdikbudristek
                                            </li>
                                            <li>
                                                LoA perguruan tinggi yang berisi nama-nama praktisi
                                            </li>
                                            <li>
                                                Nama praktisi sudah terdaftar di dalam platform
                                            </li>
                                        </ol>
                                    </LandingAccordion> */}
                                    <LandingAccordion title={'Bagaimana jika Praktisi merupakan expatriate atau warga negara asing yang tidak memiliki rekening BRI?'}>
                                        <p>
                                            Khusus Praktisi dengan status expatriate/warga negara asing bisa menggunakan rekening apapun yang dimiliki. Praktisi expatriate/warga negara asing akan dikenakan Pph 26 sesuai ketentuan (20%), kecuali bagi Praktisi dari negara yang memiliki tax treaty dengan Indonesia. Bagi Praktisi dengan tax treaty, Praktisi harus mengisi Form DGT yang ada di Direktorat Jenderal Pajak.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana sistem perhitungan honor praktisi?'}>
                                        <p>
                                            Honor praktisi ditetapkan berdasarkan Peraturan Menteri Keuangan No 60 / PMK.02 / 2021  dengan satuan orang/jam.
                                        </p>
                                        <table border="true" className="table-honor my-4">
                                            <tr>
                                                <td>Menteri/Pejabat Setingkat Menteri/<br/>Pejabat Negara Lainnya/yang disetarakan</td>
                                                <td>Rp. 1.700.000</td>
                                            </tr>
                                            <tr>
                                                <td>Pejabat Eselon I/yang disetarakan</td>
                                                <td>Rp. 1.400.000</td>
                                            </tr>
                                            <tr>
                                                <td>Pejabat Eselon II/yang disetarakan</td>
                                                <td>Rp. 1.000.000</td>
                                            </tr>
                                            <tr>
                                                <td>Pejabat Eselon III/yang disetarakan</td>
                                                <td>Rp. 900.000</td>
                                            </tr>
                                        </table>
                                        <p>
                                            Penentuan Eselon berdasarkan Peraturan Kepala Badan Kepegawaian Negara No. 35 Tahun 2011 dengan kriteria berikut:
                                            <ol>
                                                <li>Kategori jabatan fungsional/jabatan struktural (jabatan yang sedang diduduki saat ini)</li>
                                                <li>Gelar pendidikan terakhir</li>
                                                <li>Masa kerja dan usia</li>
                                            </ol>
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah honor praktisi akan dikenakan pajak?'}>
                                        <p>
                                            Honor praktisi merupakan objek pajak dan akan dikenakan pajak, maka praktisi perlu mencantumkan NPWP/TIN masing-masing.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah pajak yang dikenakan ke honor praktisi bersifat final atau tidak final?'}>
                                        <p>
                                            Pajak untuk praktisi Pegawai Negeri Sipil (PNS) bersifat final, dan untuk non-PNS bersifat tidak final. Maka, praktisi non-PNS wajib melakukan pelaporan pajak secara mandiri.
                                        </p>
                                    </LandingAccordion>
                                    {/* <LandingAccordion title={'Kapan praktisi dapat menerima honor? '}>
                                        <p>
                                            Praktisi akan menerima honor di akhir program untuk kolaborasi pendek, sedangkan untuk kolaborasi intensif, pembayaran honor akan dilakukan dalam dua tahap, yaitu di pertengahan dan akhir Program Praktisi Mengajar. 
                                        </p>
                                        <p>
                                            Pencairan dana dilaksanakan dalam rentang waktu 21-25 hari kerja terhitung sejak tanggal dokumen pengajuan pencairan dana oleh Kemendikbudristek dinyatakan telah lengkap dan telah disetujui LPDP.Khusus Praktisi dengan status expatriate/warga negara asing bisa menggunakan rekening apapun yang dimiliki. Praktisi expatriate/warga negara asing akan dikenakan Pph 26 sesuai ketentuan (20%), kecuali bagi Praktisi dari negara yang memiliki tax treaty dengan Indonesia. Bagi Praktisi dengan tax treaty, Praktisi harus mengisi Form DGT yang ada di Direktorat Jenderal Pajak.
                                        </p>
                                    </LandingAccordion> */}
                                </Col>
                                <Col xs={24} md={16}>
                                    <h4 className="font-bossaBold mt-5 mb-3">Lainnya</h4>
                                </Col>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Kenapa data yang diperlukan sangat banyak/detail?'}>
                                        <p>
                                        Data tersebut sangat penting untuk membantu perguruan tinggi dalam menemukan praktisi yang memiliki keahlian yang relevan dengan capaian pembelajaran.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem>
                            <LandingTabItem title={'Perguruan Tinggi'}>
                                <h4 className='font-bossaBold mt-5 mb-3'>
                                    Pendaftaran
                                </h4>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Apakah perguruan tinggi vokasi diizinkan untuk ikut Program Praktisi Mengajar?'}>
                                        <p>
                                            Program Praktisi Mengajar terbuka diikuti oleh perguruan tinggi vokasi.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah ada kriteria persyaratan akreditasi tertentu untuk mengakses Program Praktisi Mengajar?'}>
                                        <p>
                                            Perguruan tinggi penyelenggara adalah perguruan tinggi yang telah terakreditasi.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana cara untuk mendaftarkan proposal mata kuliah?'}>
                                    <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                                        <LandingCard className="mb-0">
                                            <div className='card-title'>
                                                1. Penerimaan Proposal
                                            </div>
                                            <div className='card-text'>
                                                Klik tombol Daftarkan Perguruan Tinggi di <a href="/pendaftaran-perguruan-tinggi">dashboard Perguruan Tinggi</a>, isi data dan unggah proposal. 
                                            </div>
                                        </LandingCard>
                                        <LandingCard className="mb-0">
                                            <div className='card-title'>
                                                2. Evaluasi Proposal
                                            </div>
                                            <div className='card-text'>
                                                Tunggu proses evaluasi proposal.
                                            </div>
                                        </LandingCard>
                                        <LandingCard className="mb-0">
                                            <div className='card-title'>
                                                3. Pengumuman
                                            </div>
                                            <div className='card-text'>
                                                Pengumuman penerimaan proposal dapat ditemukan pada akun masing-masing perguruan tinggi yang mendaftar.
                                            </div>
                                        </LandingCard>
                                    </div>
                                    </LandingAccordion>
                                </Col>
                                <Col xs={24}>
                                    <h4 className='font-bossaBold mt-5 mb-3'>
                                        Lainnya
                                    </h4>
                                </Col>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Apakah ada ketentuan dari 16 kali pertemuan, berapa persen pelaksanaan pembelajaran sinkron dan asinkron?'}>
                                        <p>
                                            Dosen dapat mengatur pelaksanaan pembelajaran sinkron dan asinkron dengan mengacu pada Permendikbud No.49 Tahun 2014: pasal 16. Untuk 1 SKS perkuliahan setara dengan 170 (seratus enam puluh) menit kegiatan belajar per minggu per semester.  Dosen dapat melaksanakan 50 menit pembelajaran sinkron, 120 menit pembelajaran asinkron. 
                                        </p>
                                        <table border="true" className="mt-2 faq-table table-honor" style={{width: '100%'}}>
                                            <thead>
                                                <tr>
                                                    <th colSpan={3}>Kuliah</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Tatap muka<br/>
                                                        50<br/> menit/minggu/semester
                                                    </td>
                                                    <td>
                                                        Penugasan terstruktur<br/>
                                                        60 menit/minggu/semester
                                                    </td>
                                                    <td>
                                                        Belajar mandiri <br/>
                                                        60 menit/minggu/semester
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        <b>Praktikum, praktik studio, praktik bengkel, praktik lapangan</b>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>
                                                        170 menit/minggu/semester
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Pelaksanaan pembelajaran akan dilangsungkan melalui platform apa?'}>
                                        <p>
                                            Pelaksanaan pembelajaran akan menggunakan SPADA Indonesia atau Learning Management System (LMS) perguruan tinggi.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem>
                            <LandingTabItem title={'Mahasiswa'}>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Mahasiswa semester berapa yang boleh ikut Program Praktisi Mengajar?'}>
                                        <p>
                                            Program Praktisi Mengajar dapat diikuti oleh mahasiswa aktif dari semester berapa pun.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah terdapat syarat IPK dan/atau minimal/maksimal semester berapa untuk ikut di Program Praktisi Mengajar?'}>
                                        <p>
                                            Tidak ada.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Apakah Program Praktisi Mengajar dapat diikuti secara paralel dengan kegiatan kampus merdeka lainnya yang salah satunya adalah magang atau studi independen?'}>
                                        <p>
                                            Kegiatan Praktisi Mengajar dapat diikuti bersamaan dengan kegiatan MBKM lainnya.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Selain perolehan SKS, apakah ada bentuk lain sebagai bukti penyelesaian dalam course tersebut? Misalnya sertifikat dan sebagainya?'}>
                                        <p>
                                            Tidak ada karena ini program ini terkait dengan mata kuliah.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem>
                            {/* <LandingTabItem title={'Institusi Praktisi'}>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Mengapa perlu ada Program Praktisi Mengajar?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana cara mendaftarkan perguruan tinggi?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana cara mendaftar sebagai praktisi dalam Program ini?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem> */}
                            {/* <LandingTabItem title={'Dosen'}>
                                <Col xs={24} md={16}>
                                    <LandingAccordion title={'Mengapa perlu ada Program Praktisi Mengajar?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana cara mendaftarkan perguruan tinggi?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                    <LandingAccordion title={'Bagaimana cara mendaftar sebagai praktisi dalam Program ini?'}>
                                        <p>
                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                        </p>
                                    </LandingAccordion>
                                </Col>
                            </LandingTabItem> */}
                        </LandingTabs>
                    </Col>
                </Row>
            </Grid>
        </Container>
    )
}

export default FAQ;
