import { useState } from 'react';
import {Grid, Row, Col, Button, Divider, Animation} from 'rsuite';
import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';
import './landingAccordion.scss';

function LandingAccordion(props){
    const [show, setShow] = useState(false);
    const handleToggle = () => {
        setShow(!show)
    };
    return (
        <Grid className="p-0" fluid>
            <Row>
                <Col xs={24} className="p-0">
                    <Button block appearance="subtle" style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '18px', lineHeight: '26px', whiteSpace: 'pre-wrap', textAlign: 'left'}} onClick={handleToggle}>
                        <div className='accordion-title' style={{color: '#054BCC', whiteSpace: 'wrap'}} >
                            {props.title}
                        </div>
                        <div className='ml-2'>
                            {!show && <PlusIcon color='#054BCC' />}
                            {show && <MinusIcon color='#054BCC' />}
                        </div>
                    </Button>
                </Col>
                <Animation.Collapse in={show}>
                    {
                        (propsAni, ref) => <Col xs={24} {...propsAni} className={show?"p-2 mt-2":""} style={{border: 'none', borderRadius: '0.75rem', transition: 'height 500ms', overflow: 'hidden'}} ref={ref}>
                            {show?props.children:null}
                        </Col>
                    }
                </Animation.Collapse>
                <Col xs={24} className="p-0">
                    <Divider className="mt-2 mb-3"/>
                </Col>
            </Row>
        </Grid>
    );
}

export default LandingAccordion;