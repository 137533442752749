function TelegramPraktisiMengajar(){
    window.location.href = 'https://t.me/+QY0iTbVn4OY5ZThl'
    return (
        <></>
    )
}

export default TelegramPraktisiMengajar;


