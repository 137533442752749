function SeleksiG2(){
    window.location.href = 'https://drive.google.com/file/d/1vETL9qI6diWDb3mghtH7itrTYzToXOhw/view?usp=sharing'
    return (
        <></>
    )
}

export default SeleksiG2;



