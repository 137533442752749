function PesertaBimtekPraktisiMengajar(){
    window.location.href = 'http://ugm.id/PesertaBimtekPraktisiMengajar'
    return (
        <></>
    )
}

export default PesertaBimtekPraktisiMengajar;





