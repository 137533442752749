import LandingFooter from "views/Landing/sections/LandingFooter";
import LandingNavBar from "views/Landing/sections/LandingNavBar";
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import { Outlet } from "react-router-dom";
import { Icon } from "@rsuite/icons";
import { Container, IconButton } from "rsuite";
import DOMHelper from 'rsuite/DOMHelper';

function MainLanding(){
    return (
        <Container style={{padding: 0}}>
            <LandingNavBar className="relative-navbar" />
            <Outlet />
            <IconButton onClick={() => {
                DOMHelper.scrollTop(window, 0);
            }} icon={<Icon as={ArrowUpLineIcon} style={{fontSize: '24px'}} />} appearance="primary" className="bg-primary" circle style={{position: 'fixed', bottom: '30px', right: '30px', zIndex: '999999'}} />
            <LandingFooter />
        </Container>
    )
}

export default MainLanding;