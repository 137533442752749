import {Container, Grid, Row, Col, Button} from "rsuite";

function KebijakanPrivasi(){
    return (
        <Container style={{padding: 0, background: '#FFF'}}>
            <div style={{backgroundColor: '#F5F5F5', color: "#25282B"}}>
                <Grid className="landing-grid">
                    <Row gutter={24}>
                        <Col className='mb-1' xs={24}>
                            <h2 className="landing-title font-bossaBold">
                                Kebijakan Privasi
                            </h2>
                        </Col>
                    </Row>
                </Grid>
            </div>
            <Grid className="landing-grid py-6">
                <Row gutter={24}>
                    <Col xs={24}>
                        <p>Undang-Undang Nomor 11 Tahun 2008 tentang Informasi dan Transaksi Elektronik sebagaimana telah diubah oleh Undang-Undang Nomor 19 Tahun 2016 serta seluruh peraturan pelaksananya (“UU ITE”) diundangkan oleh Pemerintah Republik Indonesia guna mengatur transaksi elektronik, termasuk pengolahan data pribadi.
                            <br/><br/>Praktisi Mengajar (“Kami”) menghormati privasi Anda dan berkomitmen untuk melindunginya melalui kebijakan privasi ini (“Kebijakan”). Kebijakan ini memberitahu pengguna (“Pengguna”) terkait hak Anda sehubungan informasi milik Anda yang sedang dan/atau akan dikumpulkan dan diproses (“Data Pribadi”), tujuan pengolahan Data Pribadi, serta persetujuan Anda atas pengolahan Data Pribadi melalui website praktisimengajar.id (“Website ini” atau “Website Kami” atau "Website Praktisi Mengajar" tergantung pada konteksnya) dan setiap produk dan layanan terkait (secara bersama-sama disebut “Layanan”). Oleh karena itu, Anda harus membaca Kebijakan Privasi ini sebelum menggunakan Website ini.
                            <br/><br/>Kebijakan ini adalah perjanjian yang mengikat secara hukum antara Anda (“Pengguna”, “Anda”, atau “milik Anda”) dan operator Website ini (“Operator”, “Kami”, atau “milik Kami”). Istilah dalam huruf besar (termasuk "Praktisi Mengajar", "Kami", dan "milik Kami") yang digunakan tetapi tidak didefinisikan dalam Kebijakan ini akan memiliki arti yang dianggap berasal dari istilah tersebut dalam Syarat dan Ketentuan Kami. Dengan mengakses, menggunakan dan/atau berinteraksi di Website ini, Anda secara tegas mengakui telah membaca, memahami dan menyetujui untuk terikat dengan ketentuan Kebijakan. Jika Anda memiliki kekhawatiran tentang memberikan informasi kepada Kami atau penggunaan informasi tersebut seperti yang dijelaskan dalam Kebijakan ini, Anda tidak dapat berpartisipasi dalam program Kami.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6 className="mb-2">Bagaimana Kami mengumpulkan informasi</h6>
                        <p>Kami mengumpulkan informasi: (i) secara otomatis melalui penggunaan Website ini oleh Anda; dan (ii) yang Anda pilih untuk diberikan kepada Kami.</p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6 className="mb-2">Pengumpulan Informasi secara Otomatis</h6>
                        <p>
                            Saat Anda mengunjungi Website ini, server Kami secara otomatis mengumpulkan informasi yang dikirim browser Anda. Data ini dapat mencakup informasi seperti: jenis dan versi browser, alamat IP perangkat Anda, zona waktu, sejumlah cookie yang terpasang di perangkat Anda, halaman web atau produk yang Anda lihat, halaman web yang Anda kunjungi atau istilah pencarian yang mengarahkan Anda ke Website ini, cara Anda berinteraksi dengan Website ini termasuk waktu dan tanggal akses, dan statistik lainnya ("Informasi Perangkat").
                            <br/>
                            <br/>
                            Menjaga data Pengguna Website ini agar tetap aman adalah prioritas utama Kami. Oleh karena itu, Kami dapat memproses Data Pribadi Pengguna yang benar-benar diperlukan untuk menjalankan Program Praktisi Mengajar. Informasi yang dikumpulkan secara otomatis digunakan sebatas untuk mengidentifikasi potensi kasus penyalahgunaan dan menetapkan informasi statistik mengenai penggunaan dan lalu lintas Website ini. Informasi statistik ini tidak diagregasikan sedemikian rupa sehingga dapat mengidentifikasi Pengguna tertentu dari sistem.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6 className="mb-2">Pengumpulan Informasi Pribadi</h6>
                        <p>
                            Anda dapat mengunjungi Website Kami tanpa memberi tahu Kami identitas Anda atau mengungkapkan informasi apa pun, yang dapat digunakan oleh seseorang untuk mengidentifikasi Anda sebagai individu tertentu yang dapat dikenali. Namun, jika Anda ingin menggunakan beberapa fitur di Website ini, Anda dapat memberikan Data Pribadi Anda atau tetap dapat memilih untuk tidak memberikan Data Pribadi Anda kepada Kami tetapi Anda mungkin tidak dapat memanfaatkan beberapa fitur di Website ini. Pengguna yang tidak yakin tentang informasi yang wajib diberikan dapat menghubungi Kami melalui <a href={`${process.env.REACT_APP_CONTACT_US_URL}`}>Support Praktisi Mengajar</a>.
                            <br/>
                            <br/>
                            Kami mengumpulkan Data Pribadi yang Anda berikan secara sadar kepada Kami. Bila diperlukan, informasi ini mencakup hal-hal berikut tetapi tidak terbatas pada nama, nama belakang, alamat, nomor identitas, dan lain-lain selama pendaftaran untuk dapat memenuhi syarat perjanjian.
                            <br/>
                            <br/>
                            Kami juga mengumpulkan Data Pribadi yang Anda berikan melalui formulir elektronik. Informasi ini dapat mencakup namun tidak terbatas pada nama, alamat, alamat surat elektronik, nomor identitas, nomor telepon, informasi finansial, informasi perpajakan, deskripsi pribadi, foto, dan data lainnya. Kami akan melakukan verifikasi dan dapat meminta Anda untuk memastikan kebenaran dan akurasi Data Pribadi melalui telepon, surat elektronik, dan sebagainya.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6 className="mb-2">Penggunaan Data Pribadi</h6>
                        <p>
                        Demi membuat Website ini dan Layanan tersedia untuk Anda, atau untuk memenuhi kewajiban hukum, kami perlu mengumpulkan dan menggunakan Data Pribadi Anda. Setiap Data Pribadi yang Anda berikan dapat digunakan untuk tujuan berikut:
                            <ol>
                                <li>menjalankan kewajiban Kami dalam program Praktisi Mengajar. Untuk itu, Kami dapat menggunakan jasa pihak ketiga untuk membantu kami melakukan verifikasi terhadap Data Pribadi yang Anda berikan.</li>
                                <li>memungkinkan Anda berpartisipasi dalam fitur yang tersedia di Website ini.</li>
                                <li>menyediakan, memelihara, mengelola, dan meningkatkan performa Website dan operasi internal Kami, termasuk pencarian sumber masalah, analisis data, pengujian, penelitian, serta tujuan-tujuan statistik dan survei lainnya.</li>
                                <li>memantau dan menganalisis tren, penggunaan, dan aktivitas sehubungan dengan Website ini.</li>
                                <li>memantau, mendeteksi dan mencegah penggunaan, atau penyalahgunaan Website ini oleh pihak yang tidak sah atau salah.</li>
                                <li>memungkinkan Kami memberikan dukungan penggunaan dan menanggapi secara efektif pertanyaan dan klaim pengguna.</li>
                                <li>memungkinkan Kami melakukan publikasi dalam batas yang wajar dalam rangka pelaksanaan Program Praktisi Mengajar.</li>
                                <li>mengirimkan materi promosi pemasaran (termasuk namun tidak terbatas pada peringatan, buletin, materi pemasaran) dengan persetujuan Anda.</li>
                                <li>menghubungi Anda bila diperlukan.</li>
                            </ol>
                        </p>
                        <p>Kami menyimpan Data Pribadi Anda hanya selama diperlukan untuk menyediakan layanan yang Anda minta dan untuk berbagai tujuan hukum atau bisnis yang sah. Hal ini termasuk periode penyimpanan: (i) yang diamanatkan oleh undang-undang, kontrak, atau kewajiban serupa yang berlaku untuk operasional program Kami; (ii) untuk melestarikan, menyelesaikan, membela atau menegakkan hak hukum/kontraktual Kami; dan (iii) untuk memelihara catatan bisnis dan keuangan yang memadai dan akurat.</p>
                    </Col>
                    {/* <Col xs={24} className="py-2">
                        <p>
                            Menjaga data pengguna website [link] agar tetap aman adalah prioritas utama Kami. Oleh karena itu, Kami dapat memproses sejumlah data pengguna, sebanyak yang benar-benar diperlukan untuk menjalankan Program Praktisi Mengajar. Informasi yang dikumpulkan secara otomatis hanya digunakan untuk mengidentifikasi kemungkinan kasus penyalahgunaan dan menyusun informasi statistik terkait penggunaan website. Informasi statistik ini tidak digabungkan sedemikian rupa hingga dapat mengidentifikasi pengguna tertentu dari sistem.
                            <br/><br/>
                            Anda dapat mengunjungi [link] tanpa memberi tahu Kami identitas Anda atau mengungkapkan informasi apa pun, yang dapat digunakan oleh seseorang untuk mengidentifikasi Anda sebagai individu tertentu yang dapat dikenali. Namun, jika Anda ingin menggunakan beberapa fitur di website [link] ini, Anda dapat memberikan Data Pribadi Anda atau dapat memilih untuk tidak memberikan Data Pribadi Anda kepada Kami tetapi Anda mungkin tidak dapat memanfaatkan beberapa fitur di website [link]. Pengguna yang tidak yakin tentang informasi yang wajib diberikan dapat menghubungi Kami melalui xxx@praktisimengajar.com.
                            <br/><br/>
                            Kami menyimpan data Anda hanya selama diperlukan untuk menyediakan layanan yang Anda minta dan untuk berbagai tujuan hukum atau bisnis yang sah. Hal ini termasuk periode penyimpanan: (i) yang diamanatkan oleh undang-undang, kontrak, atau kewajiban serupa yang berlaku untuk operasional program Kami; (ii) untuk melestarikan, menyelesaikan, membela atau menegakkan hak hukum/kontraktual Kami; atau (iii) diperlukan untuk memelihara catatan bisnis dan keuangan yang memadai dan akurat.
                        </p>
                    </Col> */}
                    <Col xs={24} className="py-2">
                        <h6>Tautan ke Website Lain</h6>
                        <p>
                            Website ini mungkin berisi tautan ke website lain yang tidak dimiliki atau dikendalikan oleh Kami. Perlu diketahui bahwa Kami tidak bertanggung jawab atas praktik privasi website lain atau pihak ketiga. Kami menyarankan Anda untuk selalu waspada ketika meninggalkan Website Kami dan membaca pernyataan privasi setiap website yang mungkin mengumpulkan Data Pribadi.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6>Keamanan informasi</h6>
                        <p>
                            Kami akan mengungkapkan informasi apa pun yang Kami kumpulkan, gunakan, atau terima untuk memenuhi kewajiban hukum, termasuk namun tidak terbatas pada perintah pengadilan dan proses hukum serupa, dan jika Kami percaya dengan itikad baik bahwa pengungkapan diperlukan untuk melindungi hak Kami, melindungi keselamatan Anda atau keselamatan orang lain, menyelidiki penipuan, atau menanggapi permintaan dari pemerintah.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6>Pengungkapan secara hukum</h6>
                        <p>
                            Kami akan mengungkapkan informasi apa pun yang Kami kumpulkan, gunakan, atau terima untuk memenuhi kewajiban hukum, termasuk namun tidak terbatas pada perintah pengadilan dan proses hukum serupa, dan jika Kami percaya dengan itikad baik bahwa pengungkapan diperlukan untuk melindungi hak Kami, melindungi keselamatan Anda atau keselamatan orang lain, menyelidiki penipuan, atau menanggapi permintaan dari pemerintah.
                            <br/><br/>
                            Kami tidak membagikan Data Pribadi Anda dengan perusahaan, organisasi, atau individu lain kecuali dalam kasus berikut:
                            <ol>
                                <li>dengan vendor, konsultan, dan penyedia layanan lain yang memerlukan akses ke informasi tersebut untuk melakukan pekerjaan atas nama Kami, berdasarkan instruksi Kami dan sesuai dengan Kebijakan Kami serta langkah-langkah kerahasiaan dan keamanan lainnya yang sesuai;</li>
                                <li>dengan pengguna website lainnya sehubungan dengan fitur profil pengguna Website ini (termasuk namun tidak terbatas pada nama, gambar profil, informasi pribadi Anda, yang telah Anda berikan di Website ini yang dapat dilihat oleh Pengguna lain, atau informasi yang Anda berikan dalam pendaftaran akun Anda untuk penggunaan forum dan komentar forum Anda mungkin terlihat oleh Pengguna forum lain); dan</li>
                                <li>menanggapi permintaan informasi jika Kami yakin pengungkapan diperlukan oleh atau sesuai dengan hukum, peraturan, atau proses hukum yang berlaku.</li>
                            </ol>
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6>Korespondensi</h6>
                        <p>
                            Jika Anda ingin menghubungi Kami untuk mempelajari Kebijakan Privasi ini lebih lanjut atau menanyakan masalah apa pun yang berkaitan dengan hak perorangan dan Data Pribadi Anda, Anda dapat mengirim email ke <a href={`${process.env.REACT_APP_CONTACT_US_URL}`}>Support Praktisi Mengajar</a>.
                        </p>
                    </Col>
                    <Col xs={24} className="py-2">
                        <h6>Perubahan</h6>
                        <p>Kami berhak mengubah Kebijakan ini dari waktu ke waktu. Perubahan akan diterbitkan melalui halaman ini di mana tanggal efektif di bagian atas menunjukkan terakhir kali Kebijakan ini diubah. Jika Kami diwajibkan oleh undang-undang perlindungan data yang berlaku untuk memberi Anda pemberitahuan atau meminta persetujuan Anda untuk setiap perubahan tersebut, Kami akan melakukannya. Anda dapat melihat kapan Kebijakan ini terakhir diperbarui dengan memeriksa tanggal "terakhir diubah" yang ditampilkan di bagian atas Kebijakan ini. Versi terbaru Kebijakan ini akan berlaku dan menggantikan Kebijakan sebelumnya segera setelah Kebijakan yang direvisi diterbitkan kecuali ditentukan lain.</p>
                    </Col>
                </Row>
            </Grid>
        </Container>
    )
}

export default KebijakanPrivasi;
