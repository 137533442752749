function DataPraktisiG2(){
    window.location.href = 'https://docs.google.com/forms/d/e/1FAIpQLSeR5gozkyfDA3ZSyvvn_iHcHIIXwE5xVI08LwgTjRXyDaVwgg/viewform?usp=sf_link'
    return (
        <></>
    )
}

export default DataPraktisiG2;



