import { Button, Col, Row } from 'rsuite';

import starIcon from 'assets/Star 1.png'
import LandingCard from 'views/Landing/components/LandingCard';

function KolaborasiPT() {
    return (
        <Row>
            <Col xs={24}>
                <LandingCard className="mb-0 p-0 d-flex align-items-stretch flex-column-xs">
                    <div className='card-image flex-1-md'>
                        <img src={require('assets/perguruan_tinggi.jpg')} alt="" />
                    </div>
                    <div className='card-content p-4 d-flex flex-column align-items-start justify-content-between flex-1-md'>
                        <div className='card-text mb-3'>
                            Jadilah kampus terdepan dengan mengundang praktisi ahli dari dunia kerja untuk merancang proses pembelajaran yang inovatif dan relevan dengan perkembangan dunia. Kami akan membantu pembiayaan untuk setiap praktisi yang diundang  berkolaborasi dalam Program ini.
                            {/* <br/><br/>
                            <a href='/deckbimtekpt' target="_blank">Materi Presentasi untuk Perguruan Tinggi</a><br/>
                            <a href='/panduanteknispt' target="_blank">Panduan Teknis Pendaftaran Perguruan Tinggi</a> */}
                        </div>
                        <div>
                            {/* <Button appearance="primary" href={'/bukupanduan'} target="_blank" className='text-uppercase btn-blue landing-button mr-1 mb-1'>Buku Panduan</Button> */}
                            <Button appearance="primary" target="_blank" href={`${process.env.REACT_APP_DASHBOARD_URL}/auth/registrasi`} className='text-uppercase bg-primary landing-button text-white mb-1'>Daftarkan Koor PT & Dosen</Button>
                            {/* <Button appearance="primary" target="_blank" href="/pendaftaran-perguruan-tinggi" className='text-uppercase bg-primary landing-button text-white mb-1'>Daftarkan Dosen Pengampu Mata Kuliah</Button> */}
                        </div>
                    </div>
                </LandingCard>
                {/* <h4 className='landing-title font-bossaBold mt-5 mb-3'>
                    Mengapa Perguruan Tinggi Perlu Terlibat Aktif dalam Program Praktisi Mengajar?
                </h4>
                <div className='d-grid grid-lg-4 grid-md-2 grid-xs-1'>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/pt-1.png') } alt="praktisi-1" height={"129px"}></img>
                        <div className='card-text'>
                            Bebas mengundang praktisi ahli dari dalam dan luar negeri sesuai kebutuhan peningkatan kualitas pembelajaran
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/pt-2.png') } alt="praktisi-2" height={"129px"}></img>
                        <div className='card-text'>
                            Mengembangkan pembelajaran yang lebih relevan dengan kebutuhan dunia kerja dan tantangan di masyarakat
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/pt-3.png') } alt="praktisi-3" height={"129px"}></img>
                        <div className='card-text'>
                            Meningkatkan penilaian positif untuk akreditasi BAN PT dan Internasional
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0 pt-0" style={{textAlign: 'center'}}>
                        <img src={ require('assets/pt-4.png') } alt="praktisi-3" height={"129px"}></img>
                        <div className='card-text'>
                            Meningkatkan kualitas lulusan
                        </div>
                    </LandingCard>
                </div>
                <h4 className='landing-title font-bossaBold mt-5 mb-3'>
                    Syarat Pendaftaran Perguruan Tinggi
                </h4>
                <Row gutter={24}>
                    <Col xs={24} md={16} >
                        <ul className='py-2 mb-0 list-syarat' style={{ listStyle: 'none'}}>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon"/></div>Perguruan tinggi terakreditasi</div></li>
                            <li><div className="d-flex align-items-start py-1"><div className="mr-1"><img src={starIcon} alt="star" className="star-icon"/></div>Memiliki Learning Management System (LMS) internal atau bersedia menggunakan SPADA.</div></li>
                        </ul>
                    </Col>
                </Row>
                <h4 className='landing-title font-bossaBold mt-5 mb-3'>
                    Langkah Mudah untuk Mendaftarkan Perguruan Tinggi 
                </h4>
                <div className='d-grid grid-lg-3 grid-md-2 grid-xs-1'>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            <h4>1. Penerimaan Proposal</h4>
                        </div>
                        <div className='card-text'>
                            Klik tombol Daftarkan Perguruan Tinggi di atas, isi data dan unggah proposal. 
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            <h4>2. Evaluasi Proposal</h4>
                        </div>
                        <div className='card-text'>
                            Tunggu proses evaluasi proposal Perguruan Tinggi.
                        </div>
                    </LandingCard>
                    <LandingCard className="mb-0">
                        <div className='card-title'>
                            <h4>3. Pengumuman</h4>
                        </div>
                        <div className='card-text'>
                            Pengumuman penerimaan proposal dapat ditemukan di dalam akun masing-masing Perguruan Tinggi yang mendaftar atau di halaman ini.
                        </div>
                    </LandingCard>
                </div> */}
            </Col>
        </Row>
    );
}

export default KolaborasiPT;
