function MateriPPGPrajab(){
    window.location.href = 'https://drive.google.com/file/d/1Kf7aCmKRK9oulHRzSZDDaVBsoE-RaG-F/view?usp=sharing'
    return (
        <></>
    )
}

export default MateriPPGPrajab;



