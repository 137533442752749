import "rsuite/dist/rsuite.min.css";
import React from "react";

import Router from "./router";
import { useRecoilValue } from "recoil";
import loadingStateService from "./recoil/Common/LoadingService";
import LoadingOverlay from "react-loading-overlay-ts";

function App() {
  const loadingState = useRecoilValue(loadingStateService);
  return (
    <div className="App">
      <LoadingOverlay
        active={loadingState}
        spinner
        text="Loading..."
        className={`loading-wrapper-custom${loadingState?' z-index-top':''}`}
      >
      </LoadingOverlay>
        <Router />

    </div>
  );
}

export default App;
