function DataPMG13(){
    window.location.href = 'https://drive.google.com/file/d/1auHf3yabDiFXuzQTE5o1gTLjeeqQK_W6/view?usp=sharing'
    return (
        <></>
    )
}

export default DataPMG13;


