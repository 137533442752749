function PesertaGDM(){
    window.location.href = 'https://drive.google.com/file/d/1qXu_GeSirCDZ2pxB4pTMAZzeQXrIQL2z/view?usp=share_link'
    return (
        <></>
    )
}

export default PesertaGDM;


