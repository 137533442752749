import { Grid, Row, Col } from 'rsuite';
import { useLowerBreakpoint } from 'lib/breakpoint';
import './LandingTentang.scss';
import LandingCard from '../components/LandingCard';
import iconTujuan1 from '../../../assets/PMicons-02.png'
import iconTujuan2 from '../../../assets/PMicons-07.png'
import iconTujuan3 from '../../../assets/PMicons-12.png'
// import videoPM from '../../../assets/video/praktisi mengajar.mp4'
const videoPM = 'https://drive.google.com/file/d/1A5Vcrz7AS_XKIlwbyPQgpSWUmcEJTobR/preview';

function LandingTentang() {
    return (
        <Grid className="landing-grid" style={{ paddingTop: useLowerBreakpoint("md") ? '2.5rem' : '5rem' }} id="about">
            <Row gutter={24}>
                <Col className='mb-4'>
                    <h1 className="landing-title font-bossaBold">
                        Tentang Praktisi Mengajar
                    </h1>
                </Col>
                <Col md={24} className="mb-5">
                    <div>
                        <p className='mb-3'>
                            Praktisi Mengajar adalah Program yang diinisiasi oleh Kementerian Pendidikan, Kebudayaan, Riset, dan Teknologi Republik Indonesia agar lulusan perguruan tinggi lebih siap untuk masuk ke dunia kerja. Program ini mendorong kolaborasi aktif praktisi ahli dengan dosen juara agar tercipta pertukaran ilmu dan keahlian yang mendalam dan bermakna antar sivitas akademika di perguruan tinggi dan profesional di dunia kerja. Kolaborasi ini dilakukan dalam mata kuliah yang disampaikan di ruang kelas baik secara luring maupun daring.
                        </p>
                        {/* <video style={{ borderRadius: '10px' }} height="345" controls src={videoPM}></video> */}
                        {/* <iframe title="tentang-pm" style={{borderRadius: '10px'}} width={"100%"} height={"345"} src={videoPM} allowFullScreen></iframe> */}
                        {/* <iframe src="https://www.youtube.com/embed/g4sDuP6gXtg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
                    </div>
                </Col>
            </Row>
            <Col md={24}>
                <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                    Tujuan Praktisi Mengajar
                </h1>
            </Col>
            <Col xs={24}>
                <div className='d-grid grid-lg-3 grid-md-3 grid-xs-1'>
                    <LandingCard className="text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={iconTujuan1} alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Menutup kesenjangan kompetensi lulusan baru dengan kebutuhan dunia kerja
                        </div>
                    </LandingCard>
                    <LandingCard className="text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={iconTujuan2} alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Mendorong kolaborasi perguruan tinggi dan industri dalam menyelenggarakan pembelajaran praktis dan aplikatif.
                        </div>
                    </LandingCard>
                    <LandingCard className="text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={iconTujuan3} alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Meningkatkan relevansi skill lulusan perguruan tinggi Indonesia dengan kebutuhan dunia kerja dan industri
                        </div>
                    </LandingCard>
                </div>
            </Col>
            {/* <Row gutter={24}>
                <Col xs={24} md={8}>
                    <LandingCard className="text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={ iconTujuan1 } alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Menutup kesenjangan kompetensi lulusan baru dengan kebutuhan DUDI
                        </div>
                    </LandingCard>
                </Col>
                <Col xs={24} md={8}>
                    <LandingCard className="text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={ iconTujuan2 } alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Mendorong kolaborasi perguruan tinggi dan industri
                        </div>
                    </LandingCard>
                </Col>
                <Col xs={24} md={8}>
                    <LandingCard className="mb-0 text-center">
                        <div className='card-title'>
                            <img className="tentang__tujuan__icon" src={ iconTujuan3 } alt="Tujuan 1"></img>
                        </div>
                        <div className='card-text mt-1'>
                            Mempersiapkan SDM Unggul bagi Indonesia
                        </div>
                    </LandingCard>
                </Col>
            </Row> */}
        </Grid>
    );
}

export default LandingTentang;
