import {Container, Grid, Row, Col, Button} from "rsuite";

function InfoSessionPT() {
    return (
        <Grid className="landing-grid">
            <Row style={{marginBottom: 2+'em'}}>
                <Col md={24}>
                    <h1 className="landing-title tentang__tujuan font-bossaBold mb-4">
                        Jadwal <i>Info Session</i> Perguruan Tinggi
                    </h1>
                    <p>Silakan mendaftar untuk menghadiri <i>info session</i> terkait penjelasan detail mengenai Perguruan Tinggi Program Praktisi Mengajar. Link zoom akan dikirimkan ke email Anda setelah Anda mendaftar. Mohon dapat memeriksa folder <i>spam</i> atau <i>updates</i> apabila Anda belum menerima link zoom pada <i>inbox</i> Anda.</p>
                </Col>
            </Row>
            <Col xs={24}>
                <div className='d-grid grid-lg-2 grid-md-2 grid-xs-1'>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi I</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            5 Agustus 2022, Pukul 08:30 - 10:00 WIB - LLDIKTI Wilayah 1, 2, 3, 7, 10, & 13
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi1" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi II</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            5 Agustus 2022, Pukul 08:30 - 10:00 WIB - LLDIKTI Wilayah 4, 5, & 6
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi2" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi III</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            5 Agustus 2022, Pukul 14:00 - 15:30 WIB - LLDIKTI Wilayah 1, 2, 3, 7, 10, & 13
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi3" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi IV</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            5 Agustus 2022, Pukul 14:00 - 15:30 WIB - LLDIKTI Wilayah 4, 5, & 6
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi4" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi V</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            6 Agustus 2022, Pukul 07:30 - 09:00 WIB - LLDIKTI Wilayah 8, 11, 15, & 16
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi5" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    <div className="landing-card text-center">
                        <div className='card-title'>
                            <h4>Sesi VI</h4>
                        </div>
                        <div style={{marginBottom: 1+'em'}}>
                            6 Agustus 2022, Pukul 07:30 - 09:00 WIB - LLDIKTI Wilayah 9, 12, & 14
                        </div>
                        <Button appearance="primary" target="_blank" href="https://bit.ly/PraktisiMengajarLLDIKTISesi6" className='text-uppercase bg-primary landing-button text-white mb-1'>Bergabung dalam Zoom</Button>
                    </div>
                    
                </div>
            </Col>
        </Grid>
    );
}

export default InfoSessionPT;

